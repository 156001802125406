@charset "UTF-8";

@import "mixins";

$header-store:false;

header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  height: vw(60px);
  background-color: var(--color-bg-header);
  transition: transform var(--transition-speed) var(--bezier), background var(--transition-speed) var(--bezier);
  @include mq(tbl){
    height: pc(122px);
  }
  &:before {
    @include pd;
    width: 100%;
    z-index: 1002;
    background-color: var(--color-bg-header);
    height: vw(60px);
    transition: filter var(--transition-speed) var(--bezier);
    @include mq(tbl){
      height: pc(122px);
    }
  }
  &.is-scroll {
    &:before {
      filter: var(--drop-shadow-header);
    }
  }
  &.is-down {
    transform: translateY(-120%);
  }
  .toppage & {
    background-color: var(--color-bg-header-toppage);
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1003;
    height: vw(60px);
    @include mq(tbl){
      padding-right: unset;
      justify-content: flex-start;
      height: pc(122px);
    }
    #logo {
      position: relative;
      margin-right: auto;
      z-index: 1000;
      padding: vw(14px) 0 vw(19px) vw(19px);
      @include mq(tbl){
        padding: pc(33px) 0 pc(46px) pc(52px);
        margin-right: unset;
      }
      a {
        display: block;
        picture {
          display: block;
          width: vw(150px);
          @include mq(tbl){
            width: pc(243px);
          }
        }
      }
    }
    #gnav {
      display: none;
      position: fixed;
      inset: 0;
      z-index: 1001;
      background-color: var(--color-menu-bg);
      padding: vw(75px) vw(40px);
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      transform: translateY(-120%);
      transition: transform var(--transition-speed) var(--bezier);
      display: flex;
      flex-direction: column;
      @include mq(tbl){
        position: static;
        display: block;
        padding: 0 var(--space-medium) 0 0;
        background-color: transparent;
        transform: translateX(0);
        overflow: visible;
        align-self: stretch;
        margin-left: auto;
      }
      @include mq(pc){
        padding: 0 pc(25px) 0 0;
      }
      &.show {
        transform: translateX(0);
      }
      .main {
        @include mq(tbl) {
          display: flex;
          height: 100%;
        }
        li {
          position: relative;
          &.top {
            @include mq(tbl) {
              display: none;
            }
          }
          &.active {
            span {
              &::before {
                content: '';
                position: absolute;
                background-color: var(--color-brand);
                left: pc(-9px);
                right: pc(-9px);
                bottom: pc(-9px);
                top: pc(-9px);
                border-radius: pc(6px);
                z-index: -1;
              }
            }
          }
          a {
            display: block;
            padding-block: vw(11px);
            font-size: vw(20px);
            color: var(--color-text);
            @include heading;
            &.strong {
              color: var(--color-accent);
            }
            @include mq(tbl) {
              padding-inline: pc(18px);
              font-size: pc(16px);
              color: var(--color-text);
              height: 100%;
              display: flex;
              align-items: center;
            }
            span {
              position: relative;
            }
            @media (any-hover: hover) {
              &:hover {
                span {
                  &::before {
                    content: '';
                    position: absolute;
                    background-color: var(--color-brand);
                    left: pc(-9px);
                    right: pc(-9px);
                    bottom: pc(-9px);
                    top: pc(-9px);
                    border-radius: pc(6px);
                    z-index: -1;

                  }
                }
              }
            }
          }
        }
      }
    }
    /*
    #header_botton_01, #header_botton_02 {
      height: var(--height-header);
      width: var(--width-header-icon);
      padding: 0;
      display: flex;
      text-align: center;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mq(tbl) {
        display: flex;
        height: var(--height-header-pc);
        width: var(--width-header-icon-pc);
        transition: background var(--transition-speed-fast) var(--bezier);
      }
      i {
        display: block;
        padding: 0 0 var(--space-xxxsmall);
        @include mq(tbl) {
          padding-bottom: var(--space-xsmall);
        }
        img {
          width: var(--width-header-icon-image);
          @include mq(tbl) {
            width: var(--width-header-icon-image-pc);
          }
        }
      }
      span {
        display: block;
        color: var(--color-text-on-accent);
        font-size: var(--font-size-xxxsmall);
        white-space: nowrap;
        transform: scale(.8);
        @include mq(tbl) {
          font-size: var(--font-size-xsmall);
          transform: scale(1);
        }
      }
    }
    #header_botton_01 {
      background-color: var(--color-brand);
      @include mq(tbl) {
        @include hoverBGColor(var(--color-brand-weak));
      }
    }
    #header_botton_02 {
      background-color: var(--color-accent);
      @include mq(tbl) {
        @include hoverBGColor(var(--color-accent-weak));
      }
    }
    */
    #menuButton {
      position: absolute;
      right: 0;
      top: 0;
      width: vw(60px);
      height: vw(60px);
      cursor: pointer;
      z-index: 1002;
      display: grid;
      
      justify-content: center;
      align-content: center;
      gap: vw(4px);
      @include mq(tbl){
        display: none;
      }
      span {
        background-color: var(--color-menu-bar);
        width: vw(18px);
        height: vw(2px);
        &.top {
          top: vw(26px);
          transition: transform var(--transition-speed-fast) var(--bezier);
          transform-origin: vw(8.5px) center;
        }
        &.bottom {
          top: vw(32px);
          transition: transform var(--transition-speed-fast) var(--bezier);
          transform-origin: vw(8.5px) center;
        }
      }
      @keyframes top {
        0% { transform: rotateZ(0deg) translateY(0); }
        50% { transform: rotateZ(0deg) translateY(vw(3px)); }
        100% { transform: rotateZ(30deg) translateY(vw(3px)); }
      }
      @keyframes bottom {
        0% { transform: rotateZ(0deg) translateY(0); }
        50% { transform: rotateZ(0deg) translateY(vw(-3px)); }
        100% { transform: rotateZ(-30deg) translateY(vw(-3px)); }
      }
      &.show {
        background-color: var(--color-menu-bg);
        span {
          background-color: var(--color-menu-bar-active);
          &.top {
            animation: top .2s linear forwards;
          }
          &.bottom {
            animation: bottom .2s linear forwards;
          }
        }
      }
    }
  }
}
