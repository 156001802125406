

$breakpoints: (
  'min': 'print, screen and (max-width: 374px)',
  'sp': 'print, screen and (min-width: 375px)',
  'spl': 'print, screen and (max-width: 768px) and (orientation:landscape)',
  'tb': 'print, screen and (min-width: 768px)',
  'tbl': 'print, screen and (min-width: 1080px)',
  'pc': 'print, screen and (min-width: 1280px)',
) !default;


@mixin pd {
  position: absolute;
  content: '';
  display: block;
}
@mixin mq($breakpoint: tbl) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
@mixin sp_pc {
  &.sp {
    @include mq(tbl) {
      display: none;
    }
  }
  &.pc {
    display: none;
    @include mq(tbl) {
      display: block;
    }
  }
}
@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
@mixin container($sp:var(--padding-sp),$type:normal) {
  padding-left: $sp;
  padding-right: $sp;
  @include mq(tbl) {
    padding-left: calc(50% - (var(--width-container) / 2));
    padding-right: calc(50% - (var(--width-container) / 2));
  }
  @if $type == wide {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-wide) / 2));
      padding-right: calc(50% - (var(--width-container-wide) / 2));
    }
  }
  @if $type == narrow {
    @include mq(pc) {
      padding-left: calc(50% - (var(--width-container-narrow) / 2));
      padding-right: calc(50% - (var(--width-container-narrow) / 2));
    }
  }
}

@mixin hoverOp($op:0.7) {
  transition: opacity var(--transition-speed-fast) var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      opacity: $op;
    }
  }
}
@mixin hoverColor($color:var(--color-brand)) {
  transition: color var(--transition-speed-fast) var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      color: $color;
    }
  }
}
@mixin hoverBGColor($color:var(--color-brand)) {
  transition: background-color var(--transition-speed-fast) var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      background-color: $color;
    }
  }
}
@mixin hoverShadow {
  transition: box-shadow var(--transition-speed-fast) var(--bezier), translate var(--transition-speed-fast) var(--bezier);
  @media (any-hover: hover) {
    &:hover {
      translate: 0 -5px;
      box-shadow: 0px 45px 13px 0px rgba(0, 0, 0, 0.00), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    }
  }
}
@mixin fs($fts, $lin: 14,$ls:0) {
  $rem: calc($fts / 10);
  $linS: calc($lin / $fts);
  $lsm: calc($ls * 0.001);
  font-size: #{$fts}px;
  font-size: #{$rem}rem;
  line-height: $linS;
  letter-spacing: #{$lsm}em;
  
}
@mixin slickdots($width:8px,$height:8px) {
  text-align: center;
  li {
    display: inline-block;
    width: auto;
    padding: 0;
    margin: 0 5px;
    button {
      border: none;
      background-color: var(--color-surface-weaker);
      color: transparent;
      height: $height;
      width: $width;
      padding: 0;
      cursor: pointer;
      border-radius: 0;
    }
    &.slick-active {
      button {
        background-color: var(--color-brand);
      }
    }
  }
}

@mixin grid($cols,$gap-vert,$gap-horiz) {
  display: grid;
  grid-template-columns: repeat($cols, 1fr);
  grid-template-rows: repeat(1, auto);
  gap: $gap-vert $gap-horiz;
}

@mixin caption {
  text-align: center;
  margin-top: vw(12px);
  color: var(--color-text-weaker);
  font-size: vw(12px);
  line-height: var(--line-height-small);
  @include heading;
  @include mq(tbl) {
    margin-top: pc(12px);
    font-size: pc(14px);
  }
  &.shrink {
    margin-left: auto;
    margin-right: auto;
  }
  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
}
@mixin heading {
  font-family: var(--font-family-heading);
  font-weight: 700;
}
@mixin headingMedium {
  @include heading;
  font-size: vw(18px);
  line-height: var(--line-height-xsmall);
  word-break: auto-phrase;
  @include mq(tbl) {
    font-size: pc(28px);
  }
}
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}
@function vw($px, $base: 375px) {
  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'vw()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(strip-unit($px) / strip-unit($base)) * 100vw;
  @return $value;
}
@function pc($px, $base: 1280px) {
  $value: $px;
  @if (unit($px) != 'px') {
    @warn 'vw()の引数にpx以外の値を指定しても計算できません';
    @return $value;
  }
  $value: calc(strip-unit($px) / strip-unit($base)) * 100vw;
  @return min($px, $value);
}
@mixin shadow {
  filter: drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.10)) drop-shadow(0px 41px 41px rgba(0, 0, 0, 0.09)) drop-shadow(0px 92px 55px rgba(0, 0, 0, 0.05));
}
@mixin heading-xsmall {
  font-size: vw(12px);
  @include heading;
  word-break: auto-phrase;
  line-height: var(--line-height-xsmall);
  margin-left: vw(2px);
  @include mq(tbl) {
    font-size: pc(16px);
    margin-left: pc(2px);
  }
}
@mixin heading-small {
  font-size: vw(28px);
  line-height: var(--line-height-xsmall);
  @include heading;
  word-break: auto-phrase;
  @include mq(tbl) {
    font-size: pc(36px);
  }
}
@mixin heading-medium {
  font-size: vw(44px);
  line-height: var(--line-height-xsmall);
  @include heading;
  word-break: auto-phrase;
  @include mq(tbl) {
    font-size: pc(58px);
  }
}
@mixin image-radius {
  border-radius: vw(7px);
  @include mq(tbl) {
    border-radius: pc(7px);
  }
}
@mixin gsap01 {
  opacity: 0;
  translate: 0 vw(20px);
  transition:
    filter var(--bezier) var(--transition-speed-slow),
    opacity var(--bezier) var(--transition-speed-slow),
    translate var(--bezier) var(--transition-speed-slow),
    rotate var(--bezier) .7s var(--transition-speed-slow);
  @include mq(tbl) {
    translate: 0 pc(20px);
  }
}
@mixin gsap01-active {
  @include shadow;
  opacity: 1;
  translate: 0 0;
}
@mixin gsap02 {
  opacity: 0;
  translate: 0 vw(20px);
  transition:
    opacity var(--bezier) var(--transition-speed-slow),
    translate var(--bezier) var(--transition-speed-slow);
  @include mq(tbl) {
    translate: 0 pc(20px);
  }
}
@mixin gsap02-active {
  opacity: 1;
  translate: 0 0;
}