@charset "UTF-8";

@import "mixins";

.navigation.newslist {
  margin-top: vw(40px);
  @include mq(tbl) {
    margin-top: pc(60px);
  }
  .inner {
    @include container;
    ul {
      border-bottom: vw(1px) solid var(--color-border-weak);
      @include mq(tbl) {
        border-bottom-width: pc(1px);
      }
      li {
        border-top: vw(1px) solid var(--color-border-weak);
        @include mq(tbl) {
          border-top-width: pc(1px);
        }
        a {
          display: block;
          padding-block: vw(15px);
          color: var(--color-text);
          @include mq(tbl) {
            display: flex;
            align-items: center;
            gap: pc(20px);
            position: relative;
            padding-block: pc(15px);
          }
          .date {
            font-size: vw(10px);
            @include mq(tbl) {
              width: 5.5em;
              font-size: pc(12px);
              height: 1lh;
            }
          }
          .cat {
            display: inline-block;
            margin-left: vw(4px);
            font-size: vw(10px);
            color: var(--color-text);
            @include heading;
            background-color: var(--color-surface);
            padding: vw(4px) vw(8px);
            text-align: center;
            @include mq(tbl) {
              font-size: pc(12px);
              padding: pc(4px) pc(8px);
              margin-left: pc(4px);
              width: pc(120px);
            }
          }
          h2 {
            position: relative;
            margin-top: vw(6px);
            font-size: vw(14px);
            line-height: var(--line-height-medium);
            @include heading;
            @include mq(tbl) {
              margin-top: unset;
              flex: 1;
              font-size: pc(18px);
              @include hoverColor(var(--color-accent));
            }
          }
        }
      }
    }
  }
}
