@charset "UTF-8";

@import "mixins";

.static.icon_num {
  margin-top: vw(60px);
  @include container;
  @include mq(tbl) {
    margin-top: pc(80px);
  }
  &.set2 {
    .inner {
      grid-template-columns: repeat(2, 1fr);
      @include mq(tbl) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &.set3 {
    .inner {
      grid-template-columns: repeat(2, 1fr);
      @include mq(tbl) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  .inner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: vw(16px);
    @include mq(tbl) {
      grid-template-columns: repeat(1, 1fr);
      gap: pc(48px);
    }
    .unit {
      text-align: center;
      padding: vw(20px) vw(10px);
      background-color: var(--color-surface);
      border-radius: vw(17px);
      @include mq(tbl) {
        border-radius: pc(17px);
        padding: pc(35px) pc(5px);
      }
      &:has(.large) {
        padding: vw(10px) vw(5px);
        @include mq(tbl) {
          padding: pc(15px) pc(5px);
        }
      }

      .image {
        img {
          width: 50%;
          aspect-ratio: 1 / 1;
          object-position: center;
          max-width: 150px;
          @include mq(tbl) {
            width: 40%;
            max-width: 200px;
          }
          &.large {
            width: 80%;
            @include mq(tbl) {
              width: 70%;
            }
          }
        }
      }
      h3 {
        @include heading;
        line-height: var(--line-height-xsmall);
        font-size: vw(17px);
        margin-top: vw(20px);
        @include mq(tbl) {
          margin-top: pc(30px);
          font-size: pc(24px);
        }
      }
      .data {
        margin-top: vw(8px);
        display: grid;
        grid-auto-flow: column;
        align-items: end;
        justify-content: center;
        @include heading;
        gap: vw(3px);
        font-family: var(--font-family-en);
        @include mq(tbl) {
          gap: pc(3px);
          margin-top: pc(10px);
        }
        em {
          color: var(--color-accent);
          font-size: vw(26px);
          @include mq(tbl) {
            font-size: pc(56px);
          }
        }
        span {
          @include heading;
          font-size: vw(14px);
          translate: 0 vw(-2px);
          @include mq(tbl) {
            font-size: pc(24px);
            translate: 0 pc(-6px);
          }
        }
      }
    }
  }
}
