@charset "UTF-8";

@import "mixins";

.top_mv {
  .images {
    position: relative;
    height: vw(600px);
    @include mq(tbl) {
      position: absolute;
      height: pc(1200px);
      width: pc(715px);
      right: 0;
    }
    @include mq(pc) {
      right: unset;
      left: 45%;
    }
    .image {
      position: absolute;
      opacity: 0;
      translate: 0 vw(80px);
      transition-property: opacity, translate, scale, filter;
      transition-duration: 1s;
      transition-delay: var(--transition-delay-base), var(--transition-delay-base), var(--transition-delay-base), .8s;
      @include mq(tbl) {
        translate: 0 pc(120px);
      }
      &.is-active {
        @include shadow;
        opacity: 1;
        translate: 0 0;
      }
      img {
        border-radius: vw(10px);
        @include mq(tbl) {
          border-radius: pc(7px);
        }
      }
      &.img01 {
        width: vw(146px);
        top: vw(95px);
        left: vw(53px);
        --transition-delay-base: 0s;
        @include mq(tbl) {
          width: pc(282px);
          top: pc(174px);
          left: auto;
          right: pc(334px);
        }
      }
      &.img02 {
        width: vw(125px);
        top: vw(347px);
        left: vw(134px);
        --transition-delay-base: .1s;
        @include mq(tbl) {
          width: pc(241px);
          top: pc(662px);
          left: auto;
          right: pc(220px);
        }
      }
      &.img03 {
        width: vw(114px);
        top: vw(-5px);
        left: vw(242px);
        --transition-delay-base: .3s;
        @include mq(tbl) {
          width: pc(228px);
          top: pc(-19px);
          left: auto;
          right: pc(14px);
        }
      }
      &.img04 {
        width: vw(83px);
        top: vw(197px);
        left: vw(243px);
        --transition-delay-base: .4s;
        @include mq(tbl) {
          width: pc(161px);
          top: pc(371px);
          left: auto;
          right: pc(88px);
        }
      }
      &.img05 {
        width: vw(76px);
        top: vw(323px);
        left: vw(26px);
        --transition-delay-base: .5s;
        @include mq(tbl) {
          width: pc(148px);
          top: pc(623px);
          left: auto;
          right: pc(521px);
        }
      }
      &.img06 {
        width: vw(64px);
        top: vw(424px);
        left: vw(292px);
        --transition-delay-base: .6s;
        @include mq(tbl) {
          width: pc(124px);
          top: pc(793px);
          left: auto;
          right: pc(31px);
        }
      }
    }

  }
  .path-blue {
    margin-top: vw(-200px);
    @include mq(tbl) {
      margin-top: pc(700px);
    }
  }
  .inner {
    @include container(vw(30px));
    @include mq(tbl) {
      padding-top: pc(290px);
    }
    .title {
      h1 {
        @include heading;
        font-size: vw(51px);
        line-height: var(--line-height-xsmall);
        @include mq(tbl) {
          font-size: pc(72px);
        }
      }
    }
    .button {
      margin-top: vw(68px);
      @include mq(tbl) {
        margin-top: pc(105px);
      }
      .p_button {
        @include heading;
        min-width: unset;
        background-color: var(--color-third);
        @include hoverBGColor(var(--color-accent));
        box-shadow: unset;
        transition: box-shadow var(--transition-speed) var(--bezier), background-color var(--transition-speed) var(--bezier);
        @include mq(tbl) {
          width: pc(163px);
          padding-block: pc(14px);
        }
        @media (any-hover: hover) {
          &:hover {
            box-shadow: 0px 45px 13px 0px rgba(0, 0, 0, 0.00), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
          }
        }
        
      }
    }
    .news {
      margin-top: vw(68px);
      border-left: vw(1px) solid var(--color-border-strong);
      padding-left: vw(16px);
      @include mq(tbl) {
        margin-top: pc(80px);
        border-left-width: pc(1px);
        padding-left: pc(16px);
      }
      h2 {
        font-family: var(--font-family-en);
        font-size: vw(12px);
        text-transform: uppercase;
        @include mq(tbl) {
          font-size: pc(12px);
        }
      }
      ul {
        li {
          margin-top: vw(18px);
          @include mq(tbl) {
            margin-top: pc(18px);
          }
          a {
            display: block;
            @include heading;
            color: var(--color-text);
            @include hoverColor(var(--color-accent));
            .date {
              font-size: vw(12px);
              @include mq(tbl) {
                font-size: pc(12px);
              }
            }
            h3 {
              margin-top: vw(4px);
              font-size: vw(18px);
              line-height: var(--line-height-xsmall);
              @include mq(tbl) {
                margin-top: pc(4px);
                font-size: pc(18px);
              }
            }
          }
        }
      }

    }
  }
}
.top_event {
  margin-top: vw(120px);
  @include gsap01;
  @include mq(tbl) {
    margin-top: pc(500px);
  }
  &.is-active {
    @include gsap01-active;
  }
  .inner {
    @include mq(tbl) {
      @include container(0px,wide);
    }
    .title {
      text-align: center;
      h1 {
        font-size: vw(26px);
        line-height: var(--line-height-xsmall);
        @include heading;
        @include mq(tbl) {
          font-size: pc(36px);
        }
      }
      p {
        font-family: var(--font-family-en);
        font-size: vw(9px);
        text-transform: uppercase;
        margin-top: vw(1px);
        @include mq(tbl) {
          font-size: pc(12px);
          margin-top: pc(3px);
        }
      }
    }
  }
}
#top_event_swiper {
  margin-top: vw(10px);
  padding-bottom: vw(60px);
  @include mq(tbl) {
    margin-top: pc(20px);
    padding-bottom: pc(80px);
  }
  .swiper-slide {
    padding: vw(30px) vw(35px) vw(60px);
    transition: scale var(--bezier) var(--transition-speed-fast);
    @include mq(tbl) {
      padding: pc(44px) pc(28px) pc(60px);
      scale: .9;
    }
    img {
      filter: drop-shadow(0px 10px 23px rgba(0, 0, 0, 0.10)) drop-shadow(0px 14px 14px rgba(0, 0, 0, 0.09)) drop-shadow(0px 18px 18px rgba(0, 0, 0, 0.05));
      border-radius: vw(7px);
      aspect-ratio: 120 / 63;
      transition: translate var(--bezier) var(--transition-speed-fast);
      object-fit: cover;
      @include mq(tbl) {
        border-radius: pc(7px);
        filter: unset;
      }
      @media (any-hover: hover) {
        &:hover {
          translate: 0 -10px;
        }
      }
    }
    &.swiper-slide-active {
      @include mq {
        scale: 1.1;
      }
      img {
        filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.10)) drop-shadow(0px 15px 15px rgba(0, 0, 0, 0.09)) drop-shadow(0px 34px 21px rgba(0, 0, 0, 0.05));
      }
    }
    &.swiper-slide-prev, &.swiper-slide-next {
      img {
        @include mq(){
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10)) drop-shadow(0px 7px 5px rgba(0, 0, 0, 0.09)) drop-shadow(0px 17px 5px rgba(0, 0, 0, 0.05));
        }
      }
    }
  }
  .swiper-button-next, .swiper-button-prev {
    bottom: vw(50px);
    background-color: var(--color-accent);
    width: vw(30px);
    height: vw(38px);
    border-radius: var(--border-radius-circle);
    margin-top: unset;
    top: unset;
    left: 50%;
    translate: vw(14px) 0;
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.10)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.05)) drop-shadow(0px 18px 7px rgba(0, 0, 0, 0.01)) drop-shadow(0px 28px 8px rgba(0, 0, 0, 0.00));
    @include hoverBGColor(var(--color-third));
    @include mq(){
      bottom: pc(50px);
      width: pc(30px);
      height: pc(38px);
      translate: pc(14px) 0;
    }
    &::after {
      content: none;
    }
    img {
      width: vw(6px);
      @include mq(){
        width: pc(6px);
      }
    }
  }
  .swiper-button-prev {
    left: unset;
    right: 50%;
    translate: vw(-14px) 0;
    @include mq(){
      translate: pc(-14px) 0;
    }
    img {
      rotate:z 180deg;
    }
  }
}
.top_about {
  margin-top: vw(40px);
  @include container;
  position: relative;
  @include mq(tbl) {
    margin-top: pc(120px);
  }
  .path-yellow {
    top: vw(100px);
    @include mq(tbl) {
      top: pc(250px);
    }
  }
  .inner {
    position: relative;
    padding: vw(50px) vw(20px);
    @include mq(tbl) {
      padding: pc(80px) pc(80px);
    }
    @include mq(pc) {
      padding: pc(127px) pc(122px);
    }
    &::before, &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color-surface);
      border-radius: vw(40px);
      z-index: -1;
      mix-blend-mode: soft-light;
      @include mq(tbl) {
        border-radius: pc(80px);
      }
    }
    &::before {
      z-index: -2;
      mix-blend-mode: unset;
    }
    .en {
      display: none;
      @include mq(tbl) {
        display: block;
        writing-mode: vertical-rl;
        position: absolute;
        right: pc(70px);
        top: pc(85px);
        font-family: var(--font-family-en);
        font-weight: var(--font-weight-bold);
        font-size: pc(16px);
        text-transform: uppercase;
        &::after {
          content: '';
          position: absolute;
          height: pc(82px);
          width: pc(1px);
          background-color: var(--color-border-strong);
          translate: pc(-7px) pc(10px);
        
        }
      }
    }
    .title {
      h1 {
        @include heading-medium;
        translate: vw(-3px) 0;
        @include mq(tbl) {
          translate: pc(-4px) 0;
        }
        .coverOut {
          &::before {
            background-color: var(--color-surface);
          }
        }
      }
      p {
        font-family: var(--font-family-en);
        font-size: vw(10px);
        text-transform: uppercase;
        margin-top: vw(3px);
        @include mq(tbl) {
          display: none;
        }
      }
    }
    .desc {
      margin-top: vw(50px);
      @include mq(tbl) {
        margin-top: pc(60px);
      }
      p {
        font-size: vw(14px);
        line-height: var(--line-height-xlarge);
        font-weight: 700;
        padding-bottom: 1em;
        @include mq(tbl) {
          font-size: pc(16px);
        }
        &:last-child {
          padding-bottom: unset;
        }
      
      }
    }
    .button {
      margin-top: vw(30px);
      @include mq(tbl) {
        margin-top: pc(70px);
      }
      .p_button {
        min-width: unset;
        @include mq(tbl) {
          min-width: pc(214px);
        }
      }
    }
    .image {
      position: relative;
      z-index: 2;
      padding-inline: vw(60px);
      margin-top: vw(30px);
      translate: vw(-20px) 0;
      @include mq(tbl) {
        position: absolute;
        bottom: pc(135px);
        right: pc(45px);
        padding-inline: 0;
        margin-top: unset;
        width: pc(317px);
        height: pc(425px);
      }
      @include mq(pc) {
        right: pc(-45px);
      }
      &.is-active {
        &::after {
          @include gsap01-active;
          rotate: z 14deg;
          scale: .95;
        }
        img {
          @include gsap01-active;
          rotate : z -7.5deg;
        }
      }
      &::after {
        @include pd;
        background-image: url(/images/card.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        aspect-ratio: 302 / 423;
        z-index: 2;
        left: 0;
        top: 0;
        bottom: 0;
        margin-inline: vw(60px);
        transform-origin: center bottom;
        opacity: 0;
        translate: 0 vw(20px);
        transition:
          filter var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          opacity var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          translate var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          scale var(--bezier) .7s var(--transition-speed-slow),
          rotate var(--bezier) .7s var(--transition-speed-slow);
        @include mq(tbl) {
          margin-inline: unset;
          translate: 0 pc(20px);
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 3;
        opacity: 0;
        translate: 0 vw(20px);
        @include gsap01;
        @include mq(tbl) {
          height: auto;
          max-width: 100%;
          translate: 0 pc(20px);
        }
      }
    }
  }
}
.top_business {
  margin-top: vw(150px);
  @include mq(tbl) {
    margin-top: pc(200px);
    padding-bottom: pc(180px);
    @include container;
  }
  .inner {
    position: relative;
    @include mq(tbl) {
      padding-left: pc(28px);
    }
    .en {
      display: none;
      @include mq(tbl) {
        display: block;
        writing-mode: vertical-rl;
        position: absolute;
        left: pc(-42px);
        top: pc(-30px);
        font-family: var(--font-family-en);
        font-weight: var(--font-weight-bold);
        font-size: pc(16px);
        text-transform: uppercase;
        &::after {
          content: '';
          position: absolute;
          height: pc(82px);
          width: pc(1px);
          background-color: var(--color-border-strong);
          translate: pc(-7px) pc(10px);
        
        }
      }
    }
    > .title {
      padding-inline: vw(16px);
      @include mq(tbl) {
        padding-inline: unset;
      }
      h1 {
        @include heading-medium;
        translate: vw(-3px) 0;
        @include mq(tbl) {
          translate: pc(-4px) 0;
        }
      }
      p {
        font-family: var(--font-family-en);
        font-size: vw(10px);
        text-transform: uppercase;
        margin-top: vw(3px);
        @include mq(tbl) {
          display: none;
        }
      }
    }
    .desc {
      margin-top: vw(60px);
      padding-inline: vw(16px);
      @include mq(tbl) {
        margin-top: pc(74px);
        padding-inline: unset;
      }
      p {
        font-size: vw(14px);
        line-height: var(--line-height-xlarge);
        font-weight: 700;
        padding-bottom: 1em;
        @include mq(tbl) {
          font-size: pc(16px);
        }
        &:last-child {
          padding-bottom: unset;
        }
      
      }
    }
    .button {
      margin-top: vw(30px);
      padding-inline: vw(16px);
      @include mq(tbl) {
        margin-top: pc(70px);
        padding-inline: unset;
      }
      .p_button {
        min-width: unset;
        @include mq(tbl) {
          min-width: pc(214px);
        }
      }
    }
  }
  .slide {
    margin-top: vw(30px);
    position: relative;
    height: vw(515px);
    opacity: 0;
    translate: 0 vw(80px);
    transition:
      opacity var(--bezier) 1s,
      translate var(--bezier) 1s;
    @include mq(tbl) {
      position: absolute;
      left: 50%;
      margin-top: pc(0px);
      height: unset;
      translate: 0 pc(80px);
    }
    &.is-active {
      opacity: 1;
      translate: 0 0;
    }
    .unit {
      aspect-ratio: 338 / 473;
      width: vw(207px);
      display: grid;
      grid-template-rows: auto 1fr;
      padding: vw(11px) vw(10px);
      gap: vw(22px);
      position: absolute;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: vw(4px);
      background-color: #C5EDBF;
      box-shadow: 0px 199.97px 55.991px 0px rgba(0, 0, 0, 0.00), 0px 127.98px 51.069px 0px rgba(0, 0, 0, 0.01), 0px 71.989px 43.07px 0px rgba(0, 0, 0, 0.05), 0px 31.995px 31.995px 0px rgba(0, 0, 0, 0.09), 0px 7.999px 17.843px 0px rgba(0, 0, 0, 0.10);
      transition: translate var(--transition-speed) var(--bezier), z-index var(--transition-speed) var(--bezier), rotate var(--transition-speed) var(--bezier);
      @include mq(tbl) {
        width: pc(338px);
        padding: pc(19px) pc(18px);
        border-radius: pc(7px); 
        gap: pc(33px);
      }
      &.is-active {
        z-index: 5 !important;
      }
      &::before {
        @include pd;
        width: 100%;
        height: 100%;
        background: url(/images/grain.png) lightgray 0% 0% / 30px 30px repeat;
        mix-blend-mode: overlay;
        z-index: 2;
        border-radius: vw(4px);
        opacity: .1;
        @include mq(tbl) {
          border-radius: pc(7px); 
        }
      }
      &.unit01 {
        background-image: url(/images/top_card_bg_01.png);
      }
      &.unit02 {
        background-image: url(/images/top_card_bg_02.png);
      }
      &.unit03 {
        background-image: url(/images/top_card_bg_03.png);
      }
      &.unit04 {
        background-image: url(/images/top_card_bg_04.png);
      }
      &.pos01 {
        translate: vw(24px) vw(113px);
        rotate: z -3.4deg;
        z-index: 4;
        @include mq(tbl) {
          translate: pc(0px) pc(166px);
        }
      }
      &.pos02 {
        translate: vw(60px) vw(74px);
        rotate: z 2.8deg;
        z-index: 3;
        @include mq(tbl) {
          translate: pc(63px) pc(104px);
        }
      }
      &.pos03 {
        translate: vw(91px) vw(41px);
        rotate: z -4deg;
        z-index: 2;
        @include mq(tbl) {
          translate: pc(114px) pc(49px);
        }
      }
      &.pos04 {
        translate: vw(125px) vw(10px);
        rotate: z 4deg;
        z-index: 1;
        @include mq(tbl) {
          translate: pc(170px) pc(0px);
        }
      }
      .image {
        img {
          border-radius: vw(3.5px);
          aspect-ratio: 187 / 118;
          box-shadow: 0px 6.153px 12.306px 0px rgba(0, 0, 0, 0.07);
          @include mq(tbl) {
            border-radius: pc(6px);
          }
        }
      }
      .title {
        text-align: center;
        h2 {
          @include heading-small;
          color: var(--color-white, #FFF);
          @include mq(tbl) {
          }
        }
        p {
          margin-top: vw(3px);
          font-family: var(--font-family-en);
          font-size: vw(10px);
          color: var(--color-brand, #FF0);
          font-weight: var(--font-weight-bold);
          @include mq(tbl) {
            margin-top: pc(3px);
            font-size: pc(16px);
          }
        }
      }
    }
  }
  .arrows {
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: vw(28px);
    margin-top: vw(-80px);
    @include mq(tbl) {
      gap: pc(20px);
      margin-top: unset;
      position: absolute;
      right: 0;
      bottom: -200px;
    }
    .arrow {
      background-color: var(--color-accent);
      width: vw(30px);
      height: vw(38px);
      display: grid;
      place-items: center;
      border-radius: var(--border-radius-circle);
      cursor: pointer;
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.10)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.09)) drop-shadow(0px 10px 6px rgba(0, 0, 0, 0.05)) drop-shadow(0px 18px 7px rgba(0, 0, 0, 0.01)) drop-shadow(0px 28px 8px rgba(0, 0, 0, 0.00));
      @include hoverBGColor(var(--color-third));
      transition: opacity var(--transition-speed) var(--bezier), background-color var(--transition-speed-fast) var(--bezier);
      @include mq(){
        bottom: pc(50px);
        width: pc(30px);
        height: pc(38px);
      }
      img {
        width: vw(6px);
        @include mq(){
          width: pc(6px);
        }
      }
      &.prev {
        left: unset;
        right: 50%;
        img {
          rotate:z 180deg;
        }
      }
      &.is-disabled {
        opacity: .2;
        pointer-events: none;
      }
    }
    
  }
}
.top_feature {
  margin-top: vw(150px);
  @include container;
  @include mq(tbl) {
    margin-top: pc(280px);
  }
  .inner {
    position: relative;
    padding: vw(35px) vw(20px);
    @include mq(tbl) {
      padding: pc(110px) pc(80px) pc(130px);
    }
    &::before, &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color-surface);
      border-radius: vw(40px);
      z-index: -1;
      mix-blend-mode: soft-light;
      @include mq(tbl) {
        border-radius: pc(80px);
      }
    }
    &::before {
      z-index: -2;
      mix-blend-mode: unset;
    }
    .title {
      text-align: right;
      h1 {
        @include heading-small;
        translate: vw(3px) 0;
        @include mq(tbl) {
          translate: pc(4px) 0;
        }
      }
      p {
        font-family: var(--font-family-en);
        font-size: vw(10px);
        text-transform: uppercase;
        margin-top: vw(3px);
        @include mq(tbl) {
          margin-top: pc(3px);
          font-size: pc(12px);
        }
      }
    }
    .unit {
      margin-top: vw(80px);
      display: grid;
      grid-template: 
        "num image" auto
        "text text" auto
        / auto 1fr;
      column-gap: vw(20px);
      row-gap: vw(40px);
      @include mq(tbl) {
        margin-top: pc(130px);
        grid-template: 
          "num text image" auto
          / auto 1fr auto;
        column-gap: unset;
        row-gap: unset;
      }
      &:first-child {
        margin-top: vw(60px);
        @include mq(tbl) {
          margin-top: pc(90px);
        }
      }
      .num {
        grid-area: num;
        font-size: vw(38px);
        font-family: var(--font-family-en);
        @include mq(tbl) {
          font-size: pc(38px);
          padding-right: pc(20px);
        }
      }
      .text {
        grid-area: text;
        @include mq(tbl) {
          padding-left: pc(24px);
          border-left: pc(1px) solid var(--color-border-strong);
        }
        .subtitle {
          h2 {
            @include heading-medium;
            @include mq(tbl) {
              translate: 0 pc(-5px);
            }
          }
          p {
            font-family: var(--font-family-en);
            font-size: vw(10px);
            text-transform: uppercase;
            margin-top: vw(3px);
            translate: vw(2px) 0;
            @include mq(tbl) {
              margin-top: unset;
              font-size: pc(12px);
              translate: pc(2px) 0;
            }
          }
        }
        .lead {
          @include heading;
          margin-top: vw(20px);
          line-height: var(--line-height-small);
          font-size: vw(18px);
          @include mq(tbl) {
            margin-top: pc(32px);
            font-size: pc(22px);
          }
          .coverOut {
            background-color: var(--color-accent);
            color: var(--color-text-on-accent);
            display: inline-block;
            line-height: var(--line-height-xsmall);
            &::before {
              background-color: var(--color-surface);
            }
          }
        }
        .desc {
          font-size: vw(13px);
          line-height: var(--line-height-xlarge);
          margin-top: vw(20px);
          @include mq(tbl) {
            margin-top: pc(28px);
            font-size: pc(14px);
          }
          
        }
      }
      .img {
        grid-area: image;
        text-align: right;
        @include mq(tbl) {
          padding-left: pc(75px);
        }
        img {
          border-radius: vw(10px);
          @include gsap01;
          width: vw(160px);
          @include mq(tbl) {
            border-radius: pc(10px);
            width: pc(185px);
          }
        }
        &.is-active {
          img {
            @include gsap01-active;
          }
        }
      }

    }
  }

}
.top_philosophy {
  margin-top: vw(100px);
  @include mq(tbl) {
    margin-top: pc(180px);
  }
  .inner {
    @include container;
    .title {
      @include mq(tbl) {
        margin-left: pc(80px);
      }
      p {
        font-family: var(--font-family-en);
        font-size: vw(13px);
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        @include mq(tbl) {
          font-size: pc(20px);
        }
      }
      h1 {
        @include heading-medium;
        line-height: 1.1;
        margin-top: vw(10px);
        translate: vw(-2px) 0;
        @include mq(tbl) {
          margin-top: pc(20px);
          translate: pc(-3px) 0;
        }
      }
    }
    .text {
      margin-top: vw(50px);
      @include mq(tbl) {
        margin-top: pc(80px);
        margin-right: pc(55px);
      }
      p {
        inline-size: fit-content;
        margin-left: auto;
        margin-top: vw(14px);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-xlarge);
        box-decoration-break: clone;
        @include mq(tbl) {
          margin-top: pc(18px);
        }
        .coverOut {
          line-height: 1.5;
        }
      }
    }
  }
}
.top_aside {
  margin-top: vw(150px);
  @include container;
  @include mq(tbl) {
    margin-top: pc(300px);
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: pc(35px);
  }
  + .contactus {
    margin-top: vw(30px);
    @include mq(tbl) {
      margin-top: pc(70px);
    }
  }
  .inner {
    background-color: var(--color-surface);
    padding: vw(30px);
    border-radius: vw(17px);
    @include mq(tbl) {
      padding: pc(50px) pc(35px) pc(40px) pc(35px);
      border-radius: pc(17px);
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 3;
      row-gap: pc(40px);
    }
    &.top_news {
      margin-top: vw(30px);
      @include mq(tbl) {
        margin-top: unset;
      }
    }
  }
  .link {
    text-align: right;
    margin-top: vw(30px);
    @include mq(tbl) {
      margin-top: unset;
    }
    .p_button_b {
      min-width: unset;
      @include mq(tbl) {
        min-width: pc(200px);
      }
    }
  }
  .title {
    h2 {
      @include heading-small;
    }
    p {
      margin-top: vw(1px);
      margin-left: vw(2px);
      font-size: vw(10px);
      line-height: var(--line-height-xsmall);
      font-family: var(--font-family-en);
      text-transform: uppercase;
      @include mq(tbl) {
        font-size: pc(12px);
        margin-top: pc(5px);
        margin-left: pc(2px);
      }
    }
  }
  table {
    width: 100%;
    margin-top: vw(30px);
    @include mq(tbl) {
      margin-top: unset;
    }
    th,td {
      border-top: vw(1px) solid var(--color-border-weak);
      vertical-align: top;
      padding-block: vw(16px);
      min-width: vw(100px);
      font-size: vw(12px);
      line-height: var(--line-height-medium);
      @include mq(tbl) {
        border-top-width: pc(1px);
        font-size: pc(14px);
        padding-block: pc(16px);
        min-width: pc(100px);
      }
    }
    th {
      font-weight: var(--font-weight-bold);
      white-space: nowrap;
    }
    tr {
      &:last-child {
        th, td {
          border-bottom: vw(1px) solid var(--color-border-weak);
          @include mq(tbl) {
            border-bottom-width: pc(1px);
          }
        }
      }
    }
  }
  ul {
    width: 100%;
    margin-top: vw(30px);
    @include mq(tbl) {
      margin-top: unset;
    }
    li {
      border-top: vw(1px) solid var(--color-border-weak);
      @include mq(tbl) {
        border-top-width: pc(1px);
      }
      &:last-child {
        border-bottom: vw(1px) solid var(--color-border-weak);
        @include mq(tbl) {
          border-bottom-width: pc(1px);
        }
      }
      a {
        display: block;
        padding-block: vw(15px);
        color: var(--color-text);
        @include mq(tbl) {
          position: relative;
          padding-block: pc(15px);
        }
        .date {
          font-size: vw(10px);
          @include mq(tbl) {
            width: 5.5em;
            font-size: pc(12px);
            height: 1lh;
          }
        }
        .cat {
          display: inline-block;
          margin-left: vw(4px);
          font-size: vw(10px);
          color: var(--color-text);
          @include heading;
          background-color: var(--color-bg);
          padding: vw(4px) vw(8px);
          text-align: center;
          @include mq(tbl) {
            font-size: pc(12px);
            padding: pc(4px) pc(8px);
            margin-left: pc(4px);
            width: pc(120px);
          }
        }
        h2 {
          position: relative;
          margin-top: vw(6px);
          font-size: vw(13px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            margin-top: pc(6px);
            flex: 1;
            font-size: pc(14px);
            @include hoverColor(var(--color-accent));
          }
        }
      }
    }
  }

}