@charset "UTF-8";

@import "mixins";

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: table;
    content: '';
  }
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}


.static.slider {
  .unit {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    @include mq(tbl) {
      display: block;
      height: var(--height-slider-tablet);
    }
    @include mq(pc) {
      height: var(--height-slider-pc);
    }
    > a {
      display: block;
      position: relative;
      z-index: 1;
    }
    .text {
      padding: var(--space-large) var(--space-large);
      background-color: var(--color-overlay-on-accent);
      position: absolute;
      bottom: 0;
      width: 100%;
      @include mq(tbl) {
        background-color: transparent;
        position: absolute;
        width: var(--width-slider-text);
        left: var(--padding-slider-pc);
        top: 50%;
        bottom: auto;
        transform: translateY(-50%);
        padding: 0;
        z-index: 10;
      }
      &.right {
        text-align: right;
        @include mq(tbl) {
          left: auto;
          right: var(--padding-slider-pc);
        }
        .button {
          @include mq(tbl) {
            text-align: right;
          }
        }
      }
      &.center {
        text-align: center;
        @include mq(tbl) {
          text-align: center;
          width: var(--width-slider-text);
          left: 50%;
          transform: translate(-50%,-50%);
        }
        .button {
          @include mq(tbl) {
            text-align: center;
          }
        }
      }
      .textinner {
        display: inline-block;
      }
      h1 {
        font-size: var(--font-size-large);
        line-height: var(--line-height-medium);
        font-weight: var(--font-weight-bold);
        @include mq(tbl) {
          font-size: var(--font-size-xxlarge);
          color: var(--color-text-on-accent);
        }
        + p {
          margin-top: var(--space-xsmall);
          @include mq(tbl) {
            margin-top: var(--space-medium);
          }
        }
      }
      p {
        font-size: var(--font-size-xsmall);
        line-height: var(--line-height-medium);
        color: var(--color-text);
        margin: 0;
        @include mq(tbl) {
          font-size: var(--font-size-medium);
          color: var(--color-text-on-accent);
        }
      }
      &.black {
        h1, p {
          @include mq(tbl) {
            color: var(--color-text);
          }
        }
      }
      .button {
        margin: var(--space-small) 0 0;
        text-align: center;
        border: none;
        @include mq(tbl) {
          margin-top: var(--space-large);
          text-align: left;
        }
        a {
          background-color: var(--color-accent);
          font-size: var(--font-size-xsmall);
          color: var(--color-text-on-accent);
          display: inline-block;
          padding: var(--space-small) var(--space-large);
          position: relative;
          border-radius: var(--border-radius-xsmall);
          @include mq(tbl) {
            font-size: var(--font-size-small);
            padding: var(--space-medium) var(--space-xxlarge);
            @include hoverOp;
          }
        }
      }
    }
    .bg {
      width: 100%;
      height: var(--height-slider);
      background-position: center;
      background-size: cover;
      transition: opacity var(--transition-speed-fast) var(--bezier);
      @include mq(tbl) {
        display: block;
        height: var(--height-slider-tablet);
      }
      @include mq(pc) {
        height: var(--height-slider-pc);
      }
    }
    &.slick-active::before, &.is-active-next::before {
      opacity: 0;
      z-index: 0;
    }
    .bg.pc {
      display: none;
      @include mq(tbl) {
        display: block;
      }
    }
    .bg.sp {
      display: block;
      @include mq(tbl) {
        display: none;
      }
    }
    .bg.video {
      object-fit: cover;
    }
    &.with_bg {
      .text {
        @include mq(tbl) {
          width: var(--width-slider-text-box);
        }
        .textinner {
          @include mq(tbl) {
            display: inline-block;
            background-color: var(--color-surface);
            padding: var(--space-xlarge) var(--space-xxlarge);
          }
        }
      }
    }
  }
  .slick-arrow {
    position: absolute;
    top: auto;
    bottom: var(--space-xxxsmall);
    left: var(--space-large);
    z-index: 10;
    cursor: pointer;
    @include mq(tbl) {
      display: block !important;
      top: 50%;
      bottom: auto;
      left: var(--space-xlarge);
      transform: translateY(-50%);
      @include hoverOp(.7);
    }
    img {
      width: var(--width-slider-arrow);
      transform: rotate(180deg);
      @include mq(tbl) {
        width: var(--width-slider-arrow-pc);
      }
    }
    &.slick-next {
      left: auto;
      right: var(--space-large);
      @include mq(tbl) {
        left: auto;
        right: var(--space-xlarge);
      }
      @include mq(pc) {
      }
      img {
        transform: rotate(0deg);
      }
    }
  }
  .slick-dots {
    margin: var(--space-xlarge) 0;
    @include slickdots(20px,4px);
    @include mq(tbl) {
      display: none !important;
    }
  }
}
