@charset "UTF-8";

@import "mixins";

.component {
  strong, b {
    font-weight: var(--font-weight-bold);
  }
  a {
    color: var(--color-text-link);
    text-decoration: underline;
  }
  .text {
    p {
      font-size: vw(14px);
      line-height: var(--line-height-xlarge);
      padding-bottom: 2em;
      @include mq(tbl) {
        font-size: pc(16px);
      }
      &.note {
        font-size: vw(12px);
        line-height: var(--line-height-medium);
        padding-bottom: unset;
        @include mq(tbl) {
          font-size: pc(14px);
        }
      }
      &.heading {
        @include heading;
        font-size: vw(16px);
        padding-bottom: 1em;
        @include mq(tbl) {
          font-size: pc(20px);
        }
      }
      &.figure {
        margin-top: vw(50px);
        color: var(--color-text-weaker);
        padding-bottom: 0;
        @include mq(tbl) {
          padding-bottom: pc(60px);
        }
        img {
          &.mt-image-left,
          &.mt-image-center,
          &.mt-image-right,
          &.mt-image-none {
            margin-bottom: vw(10px) !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              margin-bottom: pc(10px) !important;
            }
          }
        }
      }
      img {
        &.mt-image-left,
        &.mt-image-center,
        &.mt-image-right {
          margin-bottom: unset !important;
        }
      }
      &:last-child {
        padding-bottom: unset;
      }
      & + .button{
        margin-top: unset;
      }
    }
    ul, ol {
      font-size: vw(14px);
      line-height: var(--line-height-xlarge);
      padding-bottom: 2em;
      @include mq(tbl) {
        font-size: pc(16px);
      }
      &:last-child {
        padding-bottom: unset;
      }
    }
    ul {
      list-style: disc;
      li {
        margin-left: 2em;
      }
    }
    ol {
      list-style: decimal;
      li {
        margin-left: 2em;
      }
    }
  }
  .image, .movie, .map {
    text-align: center;
    &.shrink {
      margin-left: auto;
      margin-right: auto;
    }
    img {
      border-radius: vw(7px);
      @include mq(tbl) {
        border-radius: pc(7px);
      }
    }
    a {
      img {
        @include hoverShadow;
      }
    }
    .caption {
      @include caption;
    }
    .child {
      padding-bottom: 2em;
      &:last-child {
        padding-bottom: unset;
      }
    }
  }
}

@import "pagetitle";
@import "extendedtitle";
@import "catchcopy";
@import "catchcopy_image";
@import "catchcopy_image_bg";
@import "conversion";
@import "heading";
@import "text_lead";
@import "text";
@import "text_image";
@import "text_map";
@import "map";
@import "image";
//@import "image_multi";
@import "movie";
//@import "movie_multi";
@import "panel";
@import "speaker";
@import "speaker_heading";
@import "column";
@import "table";
@import "button";
@import "anchor";
@import "num";
@import "wysiwyg";
@import "catalog";
@import "faq";
@import "member";
@import "iframe";
@import "pagelist";
