@charset "UTF-8";

@import "mixins";

main:has(.navigation.breadcrumbs) + footer{
  margin-top: vw(30px);
  @include mq(tbl) {
    margin-top: pc(50px);
  }
}
footer {
  margin-top: vw(70px);
  background-color: var(--color-bg-footer);
  @include mq(tbl) {
    margin-top: pc(150px);
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: grid;
      gap: pc(50px);
      grid-template:
        "footinfo footnav" auto
        / auto 56%;
    }
    .footinfo {
      grid-area: footinfo;
      .corpinfo {
        p {
          img {
            width: vw(150px);
            @include mq(tbl) {
              width: pc(243px);
            }
          }
        }
        .contact {
          margin-top: vw(22px);
          font-size: vw(12px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            margin-top: pc(32px);
            font-size: pc(14px);
          }
        }
      }
      .sns {
        display: flex;
        gap: vw(15px);
        margin-top: vw(25px);
        @include mq(tbl) {
          gap: pc(15px);
          margin-top: pc(25px);
        }
        li {
          a {
            @include hoverOp(.5);
            img {
              width: vw(20px);
              @include mq(tbl) {
                width: pc(20px);
              }
            }
          }
        }
      }
    }
    .footnav {
      margin-top: vw(30px);
      @include mq(tbl) {
        grid-area: footnav;
        margin-top: unset;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: left;
        gap: pc(50px) pc(30px);
        padding-top: pc(20px);
      }
      .unit {
        border-top: vw(1px) solid var(--color-border-weak);
        @include mq(tbl) {
          border-top: unset;
        }
        &:last-child {
          border-bottom: vw(1px) solid var(--color-border-weak);
          @include mq(tbl) {
            border-bottom: unset;
          }
        }
        h2 {
          position: relative;
          font-size: vw(12px);
          font-weight: bold;
          @include mq(tbl) {
            font-size: pc(16px);
            @include heading;
          }
          a {
            color: currentColor;
            @include hoverColor(var(--color-accent));
            display: block;
            padding-block: vw(12px);
            @include mq(tbl) {
              padding-block: unset;
            }
          }
        }
        ul {
          display: none;
          @include mq(tbl) {
            font-size: pc(13px);
            display: block;
            margin-top: pc(20px);
          }
          li {
            a {
              color: currentColor;
              display: block;
              padding-block: pc(10px);
              @include hoverColor(var(--color-accent));
            }
          }
        }
      }
    }
  }
  .copyright {
    margin-top: vw(15px);
    @include container;
    padding-bottom: vw(30px);
    font-size: vw(10px);
    @include mq(tbl) {
      margin-top: pc(60px);
      padding-bottom: pc(50px);
      font-size: pc(12px);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row-reverse;
    }
    ul {
      margin-top: vw(20px);
      display: flex;
      gap: vw(40px);
      @include mq(tbl) {
        gap: pc(20px);
        margin-top: unset;
      }
      li {
        @include mq(tbl) {
          flex: auto;
        }
        a {
          display: block;
          color: var(--color-text);
          @include hoverColor(var(--color-accent));
        }
      }
    }
    p {
      margin-top: vw(25px);
      font-size: vw(10px);
      font-family: var(--font-family-en);
      @include mq(tbl) {
        margin-top: unset;
        font-size: pc(12px);
      }
    }
  }
}

#pagetop {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  cursor: pointer;
  opacity: 0;
  transition: opacity var(--transition-speed) var(--bezier);
  display: none;
  @include mq(tbl) {
    bottom: pc(32px);
    right: pc(32px);
    display: block;
  }
  i {
    display: inline-block;
    mask-position: center center;
    mask-repeat: no-repeat;
    mask-size: pc(20px) auto;
    mask-image: var(--pagetop);
    width: pc(50px);
    height: pc(50px);
    background-color: var(--color-text);
    transition: background-color var(--transition-speed-fast) var(--bezier);
    @include mq(tbl) {
      display: block;
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: var(--color-third);
      }
    }
  }
  &.on {
    opacity: 1;
  }
}
