@charset "UTF-8";

@import "mixins";

.static.timeline {
  margin-top: vw(60px);
  @include mq(tbl) {
    margin-top: pc(80px);
  }
  .inner {
    @include container;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    .unit {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span 2;
      gap: vw(32px);
      @include mq(tbl) {
        gap: pc(45px);
        padding: 0 pc(110px);
      }
      &:last-child {
        .content {
          padding-bottom: 0;
          &::before {
            content: none;
          }
        }
      }
      time {
        font-size: vw(16px);
        font-family: var(--font-family-en);
        @include mq(tbl) {
          font-size: pc(24px);
        }
      }
      .content {
        position: relative;
        padding-bottom: vw(30px);
        @include mq(tbl) {
          padding-bottom: pc(40px);
        }
        &::before {
          @include pd;
          width: vw(1px);
          height: 100%;
          top: vw(4px);
          left: vw(-14px);
          background-color: var(--color-border);
          @include mq(tbl) {
            width: pc(1px);
            top: pc(7px);
            left: pc(-22px);
          }
        }
        &::after {
          @include pd;
          width: vw(9px);
          height: vw(9px);
          left: vw(-18px);
          top: vw(4px);
          background-color: var(--color-accent);
          border-radius: 50%;
          @include mq(tbl) {
            width: pc(9px);
            height: pc(9px);
            left: pc(-26px);
            top: pc(7px);
          }
        }
        h2 {
          @include heading;
          font-size: vw(18px);
          @include mq(tbl) {
            font-size: pc(24px);
          }
        }
        p {
          margin-top: vw(14px);
          font-size: vw(14px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: pc(16px);
            margin-top: pc(10px);
          }
        }
      }


    }
  }
}
