@charset "UTF-8";

@import "mixins";

.component.type_image_multi {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  &.set1 {
    .inner {
      @include mq(tb) {
        justify-content: center;
      }
    }
  }
  &.set2 {
    .inner {
      @include grid(1,vw(40px),0);
      @include mq(tbl) {
        @include grid(2,pc(60px),pc(60px));
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,vw(30px),vw(25px));
      @include mq(tbl) {
        @include grid(3,pc(50px),pc(25px));
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,vw(30px),vw(25px));
      @include mq(tbl) {
        @include grid(4,pc(40px),pc(20px));
      }
    }
  }
  .inner {
    @include container;
  }
}
