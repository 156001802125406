@charset "UTF-8";

@import "mixins";

.navigation.archivenav {
  margin-top: var(--margin-top-medium);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
  }
  .inner {
    @include container;
    .overflow {
      overflow: auto;
      padding-bottom: vw(10px);
      @include mq(tbl) {
        overflow: visible;
        padding: unset;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        border-bottom: vw(1px) solid var(--color-border-weak);
        @include mq(tbl) {
          flex-wrap: wrap;
          border-bottom-width: pc(1px);
        }
        li {
          font-size:  vw(12px);
          white-space: nowrap;
          margin-left: vw(4px);
          position: relative;
          &:first-child {
            margin-left: unset;
          }
          @include mq(tbl) {
            font-size: pc(16px);
            margin-left: pc(12px);
          }
          &.active,&.current_page_item {
            z-index: 2;
            a {
              color: var(--color-text-on-accent);
              background-color: var(--color-accent);
            }
          }
          a {
            display: block;
            position: relative;
            @include heading;
            font-weight: var(--font-weight-bold);
            background-color: var(--color-surface);
            border-radius: vw(7px) vw(7px) 0 0;
            padding: vw(12px) vw(32px);
            color: var(--color-text);
            letter-spacing: 0;
            transition: color var(--transition-speed-fast) var(--bezier);
            &:is(:not(.active > *,.current_page_item > *)){
              @include hoverBGColor(var(--color-brand));
            }
            @include mq(tbl) {
              padding: pc(16px) pc(48px);
              border-radius: pc(7px) pc(7px) 0 0;
            }
          }
        }
      }
    }
  }
}
