@charset "UTF-8";

@import "mixins";

.component.type_wysiwyg {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
  }
}
