@charset "UTF-8";

@import "mixins";

.component.type_table {
  margin-top: vw(50px);
  @include container;
  @include mq() {
    margin-top: pc(70px);
  }
  .inner {
    .table_set {
      position: relative;
      overflow: auto;
      &.type_01 {
        table {
          border: none;
          th, td {
            border: none;
            padding: 0;
          }
        }
      }
      &.type_02 {
        table {

        }
      }
      &.type_03 {
        table {
          th {
            background-color: var(--color-cell-bg);
          }
        }
      }
      &.type_04 {
        table {

        }
        th {
          background-color: var(--color-cell-bg-dark);
          color: var(--color-text-on-accent);
        }
      }
      &.type_05 {
        table {
          th {
            // background-color: var(--color-brand);
          }
        }
      }
      &.type_06 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-cell-bg);
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              border-right: 1px solid var(--color-border);
            }
          }
        }
      }
      &.type_07 {
        table {
          border-left: 1px solid var(--color-border);
          tr {
            &:nth-child(odd){
              background-color: var(--color-cell-bg);
            }
            &:nth-child(even){
              background-color: var(--color-surface);
            }
            th, td {
              font-weight: var(--font-weight-normal);
              border-right: vw(1px) solid var(--color-border);
              @include mq(tbl) {
                border-right: pc(1px) solid var(--color-border);
              }
            }
          }
        }
      }
      &.compact {
        table {
          th,td {
            padding: vw(8px);
            line-height: var(--line-height-small);
            @include mq(tbl) {
              padding: pc(8px);
            }
          }
        }
      }
      &.fixed {
        table {
          @include mq(tbl) {
            table-layout: fixed;
          }
        }
      }
      &.fix_left {
        table {
          th {
            position: sticky;
            left: 0;
            /*
            &:before {
              content: '';
              position: absolute;
              left: 0px;
              top: 0;
              bottom:0;
              height: 100%;
              background-color: #E7E5DF;
              width: 2px;
            }
            */
          }
        }
      }
      table {
        border-collapse: collapse;
        border-top: vw(1px) solid var(--color-border);
        border-bottom: vw(1px) solid var(--color-border);
        width: 100%;
        @include mq(tbl) {
          border-top-width: pc(1px);
          border-bottom-width: pc(1px);
        }
        th,td {
          border-top: vw(1px) solid var(--color-border);
          vertical-align: top;
          padding: vw(16px);
          min-width: vw(150px);
          font-size: vw(14px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            border-top-width: pc(1px);
            font-size: pc(16px);
            padding: pc(16px);
            min-width: pc(150px);
          }
          &.left {
            text-align: left;
          }
          &.center {
            text-align: center;
          }
          &.right {
            text-align: right;
          }
          &.min {
            min-width: auto;
          }
          .p_button {
            margin-top: vw(15px);
            @include mq(tbl) {
              margin-top: pc(15px);
            }
          }
        }
        th {
          vertical-align: middle;
          font-weight: var(--font-weight-bold);
        }
      }
    }
    .table_overflow {
      padding-top: vw(30px);
      position: sticky;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 1;
      @include mq(tbl) {
        padding-top: pc(30px);
      }
      &:before {
        content: '';
        display: block;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: vw(20px) auto;
        background-image: url(/images/swipe.svg);
        width: vw(20px);
        height: vw(22px);
        margin-left: auto;
        position: absolute;
        right: 0;
        top: 0;
        @include mq(tbl) {
          background-size: pc(20px) auto;
          width: pc(20px);
          height: pc(22px);
        }
      }
    }
  }
}
