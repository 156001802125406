@charset "UTF-8";

@import "mixins";

.component.type_anchor {
  margin-top: vw(70px);
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  .type_extendedtitle + & {
    margin-top: unset;
    @include mq(tbl) {
      margin-top: pc(-80px);
    }
  }
  + .anchor + * {
    //margin-top: vw(20px);
    @include mq(tbl) {
      margin-top: pc(40px);
    }
  }
  .inner {
    @include container;
    @include mq {
      padding: unset;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: end;
      gap: pc(40px);
    }
    &::before {
      content: 'Table of Contents';
      text-transform: uppercase;
      font-family: var(--font-family-en);
      font-size: vw(11px);
      @include mq {
        font-size: pc(12px);
        padding-top: pc(22px);
      }
    }
    ul {
      margin-top: vw(10px);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, auto);
      gap: 0 vw(16px);
      @include mq(tbl) {
        margin-top: unset;
        width: pc(205px);
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        border-top: vw(.5px) solid var(--color-border-strong);
        font-size: vw(14px);
        line-height: var(--line-height-small);
        @include heading;
        &:last-child {
          border-bottom: vw(.5px) solid var(--color-border-strong);
          @include mq(tbl) {
            border-bottom-width: pc(.5px);
          }
        }
        @include mq(tbl) {
          font-size: pc(16px);
          border-top-width: pc(.5px);
        }
        a {
          display: block;
          position: relative;
          text-decoration: none;
          color: var(--color-text);
          transition: color var(--transition-speed-fast) var(--bezier);
          display: grid;
          grid-template-columns: auto 1fr;
          gap: vw(8px);
          padding: vw(8px) vw(4px);
          @include hoverColor(var(--color-accent));
          @include mq(tbl) {
            padding: pc(16px) pc(6px);
            gap: pc(8px);
          }
          &:before {
            content: '';
            height: 1lh;
            width: vw(10px);
            background-image: var(--arrow-bottom);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            @include mq(tbl) {
              width: pc(10px);
            }
          }
        }
      }
    }
  }
}
