@charset "UTF-8";

@import "mixins";

.component.type_text {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    .text {
      @include mq(tbl) {
        padding-left: var(--padding-text-pc);
        padding-right: var(--padding-text-pc);
      }
    }
  }
  &.sp_left {
    text-align: left;
  }
  &.sp_center {
    text-align: center;
  }
  &.sp_right {
    text-align: right;
  }
  &.pc_left {
    @include mq(tbl) {
      text-align: left;
    }
  }
  &.pc_center {
    @include mq(tbl) {
      text-align: center;
    }
  }
  &.pc_right {
    @include mq(tbl) {
      text-align: right;
    }
  }
}
