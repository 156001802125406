@charset "UTF-8";

@import "mixins";

.navigation.pagenate {
  @include container;
  margin-top: vw(60px);
  @include mq(tbl) {
    margin-top: pc(80px);
  }
  .m_newslist + & {
    .inner {
      padding-top: unset;
      border-top: none;
    }
  }
  .inner {
    a, span {
      text-align: center;
      display: block;
      width: vw(30px);
      height: vw(38px);
      line-height: vw(38px);
      border-radius: var(--border-radius-circle);
      background-color: var(--color-accent);
      font-size: vw(14px);
      font-family: var(--font-family-en);
      font-weight: 700;
      color: var(--color-text-on-accent);
      @include hoverBGColor(var(--color-third));
      box-shadow: 0px 45px 13px 0px rgba(0, 0, 0, 0.00), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
      @include mq(tbl) {
        width: pc(30px);
        height: pc(38px);
        line-height: pc(38px);
        font-size: pc(18px);
      }
      img {
        width: vw(6px);
        vertical-align: middle;
        @include mq(tbl) {
          width: pc(8px);
        }
      }
      i {
        display: inline-block;
        width: vw(5px);
        height: vw(38px);
        mask-position: center center;
        mask-repeat: no-repeat;
        mask-size: contain;
        mask-image: url(/images/arrow_pagenate.svg);
        background: var(--color-text-on-accent);
        @include mq {
          width: pc(6px);
          height: pc(38px);
        }
      }
      &.current, &.current_page {
        background-color: var(--color-brand);
        color: var(--color-text);
        @include mq(tbl) {
          &:hover {
            opacity: 1;
          }
        }
      }
      &.previouspostslink, &.nextpostslink, &.link_before, &.link_next {
        // background-color: transparent;
      }
      &.previouspostslink, &.link_before {
        img, i {
          transform: scale(-1, 1);
        }
      }
    }
    // wordpress
    .wp-pagenavi {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      a, span {
        margin: 0 var(--space-xxsmall) var(--space-small);
        @include mq(tbl) {
          margin: 0 var(--space-xsmall) var(--space-medium);
        }
      }
    }
    // mt
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        margin: 0 vw(4px) vw(8px);
        @include mq(tbl) {
          margin: 0 pc(8px) pc(16px);
        }
      }
    }
  }
}
