@charset "UTF-8";

@import "mixins";

.component.type_button {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      li {
        text-align: center;
        margin: 0 calc(var(--gap-button-col) / 2) var(--gap-button-row);
        @include mq(tbl) {
          margin: 0 calc(var(--gap-button-col-pc) / 2) var(--gap-button-row-pc);
        }
      }
    }
  }
}
