@charset "UTF-8";

@import "mixins";

.navigation.localnav_bottom {
  background-color: var(--color-surface);
  margin-top: vw(80px);
  display: none;
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  .inner {
    @include container;
    padding-block: vw(48px);
    @include mq(tbl) {
      padding-block: pc(64px);
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: vw(48px);
      @include mq(tbl) {
        display: block;
        position: relative;
        padding-bottom: pc(48px);
      }
      h2 {
        @include heading;
        font-size: vw(18px);
        @include mq(tbl) {
          font-size: pc(24px);
        }
      }
      /*
      .all {
        display: none;
        font-size: var(--font-size-small);
        @include mq(tbl) {
          position: absolute;
          right: 0;
          top: 15px;
        }
        a {
          position: relative;
          padding-left: 17px;
          &:before {
            @include pd;
          }
          &:before {
            @include pd;
            background: center / contain no-repeat var(--arrow-button);
            width: 16px;
            height: 16px;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
          }
        }
      }
      */
    }
    ul {
      @include grid(2,vw(16px),vw(16px));
      @include mq(tbl) {
        @include grid(3,pc(24px),pc(24px));
      }
      li {
        &.active {
          a {
            border-bottom: vw(2px) solid var(--color-accent);
            font-weight: var(--font-weight-bold);
            @include mq(tbl) {
              border-bottom-width: pc(3px);
            }
            span {
              color: var(--color-accent);
            }
          }
        }
        a {
          border-bottom: vw(1px) solid var(--color-border-strong);
          padding: 0 0 vw(8px);
          display: block;
          @include heading;
          color: inherit;
          @include hoverColor(var(--color-accent));
          @include mq(tbl) {
            border-bottom-width: pc(1px);
            padding-bottom: pc(8px);
          }
          span {
            display: block;
            position: relative;
            padding-left: vw(16px);
            font-size: vw(13px);
            line-height: var(--line-height-medium);
            @include mq(tbl) {
              font-size: pc(16px);
              padding-left: pc(16px);
            }
            &:before,&:after {
              @include pd;
            }
            &:before {
              @include pd;
              background: center / contain no-repeat var(--arrow-button);
              width: vw(8px);
              height: vw(9px);
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              @include mq(tbl) {
                width: pc(10px);
                height: pc(12px);
              }
            }
          }
        }
      }
    }
  }
}
