@charset "UTF-8";

@import "mixins";

.component.type_column {
  margin-top: vw(70px);
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  .container {
    @include container;
    .inner {
      border-radius: vw(7px);
      padding: vw(24px);
      position: relative;
      @include mq(tbl) {
        padding: pc(48px);
        display: grid;
        grid-template-columns: var(--col-width-column-image) var(--col-width-column-text);
        grid-template-rows: auto auto 1fr;
      }
      &::before,&::after {
        content: '';
        position: absolute;
        inset: 0;
        background-color: var(--color-column-bg);
        border-radius: vw(7px);
        mix-blend-mode: soft-light;
        z-index: -1;
        @include mq(tbl) {
          border-radius: pc(24px);
        }
      }
      &::before {
        z-index: -2;
        mix-blend-mode: unset;
      }
      &.textonly {
        display: block;
      }
      h3 {
        position: relative;
        @include heading-small;
        padding-bottom: vw(16px);
        @include mq(tbl) {
          padding-bottom: pc(24px);
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
      }
      .photo {
        padding-bottom: vw(16px);
        @include mq(tbl) {
          grid-column: 1 / 2;
          grid-row: 1 / 4;
          padding-right: pc(32px);
          padding-bottom: unset;
        }
        .child {
          margin-top: vw(16px);
          text-align: center;
          @include mq(tbl) {
            &:first-of-type {
              margin-top: unset;
            }
          }
          .image_sp {
            padding-bottom: unset;
            max-width: var(--max-width-column-image);
            margin-left: auto;
            margin-right: auto;
            @include mq(tbl) {
              display: none;
            }
          }
          .image_pc {
            display: none;
            padding-bottom: unset;
            @include mq(tbl) {
              display: inline;
            }
          }
          img {
            border-radius: vw(7px);
            @include mq(tbl) {
              border-radius: pc(7px);
            }
          }
          .caption {
            @include caption;
          }
        }
      }
      h4 {
        @include heading-xsmall;
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 2 / 3;
        }
      }
      .text {
        padding-top: vw(16px);
        @include mq(tbl) {
          grid-column: 2 / 3;
          grid-row: 3 / 4;
        }
        .button {
          text-align: right;
        }
      }
    }
  }
  &.reverse {
    .container {
      .inner {
        @include mq(tbl) {
          grid-template-columns: var(--col-width-column-text) var(--col-width-column-image);
          grid-template-rows: auto auto 1fr;
        }
        h3 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
        }
        .photo {
          @include mq(tbl) {
            grid-column: 2 / 3;
            grid-row: 1 / 4;
            padding-right: unset;
            padding-left: pc(32px);
          }
        }
        h4 {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
          }
        }
        .text {
          @include mq(tbl) {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
          }
        }
      }
    }
  }
  &.vertical {
    .container {
      .inner {
        @include mq(tbl) {
          display: block;
        }
        .photo {
          @include mq(tbl) {
            padding: pc(32px) 0 pc(16px);
            display: flex;
            justify-content: center;
            gap: pc(40px);
          }
          .child {
            @include mq(tbl) {
              width: 100%;
              margin-top: unset;
            }
          }
        }
      }
    }
  }
  &.vertical_reverse {
    .container {
      .inner {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        h3 {
          grid-row: 1 / 2;
          grid-column: auto;
        }
        h4 {
          grid-row: 2 / 3;
          grid-column: auto;
        }
        .photo {
          grid-row: 4 / 5;
          grid-column: auto;
          padding-bottom: unset;
          margin-top: vw(16px);
          @include mq(tbl) {
            padding-right: unset;
            padding-left: unset;
            padding-bottom: pc(16px);
            margin-top: pc(48px);
            padding-top: unset;
            display: flex;
            justify-content: center;
          }
          .child {
            @include mq(tbl) {
              width: 100%;
            }
          }
        }
        .text {
          grid-row: 3 / 4;
          grid-column: auto;
        }
      }
    }
  }
}
