@charset "UTF-8";

@import "mixins";

.navigation.breadcrumbs {
  margin-top: vw(100px);
  @include mq(tbl) {
    margin-top: pc(170px);
  }
  .m_localnav_bottom + & {
    margin-top: unset;
  }
  .inner {
    @include container;
    ul {
      display: flex;
      flex-wrap: wrap;
      border-top: vw(1px) solid var(--color-border-weak);
      border-bottom: vw(1px) solid var(--color-border-weak);
      padding-block: vw(16px);
      @include mq(tbl) {
        border-top-width: pc(1px);
        border-bottom-width: pc(1px);
        padding-block: pc(24px);
      }
      li {
        color: var(--color-text);
        position: relative;
        margin-right: vw(3px);
        @include heading;
        font-size: vw(10px);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          font-size: pc(12px);
          margin-right: pc(3px);
        }
        &:after {
          content: '\FF0F';
          margin-left: vw(3px);
          color: var(--color-text-weaker);
          @include mq(tbl) {
            margin-left: pc(3px);
          }
        }
        &:last-child {
          &:after {
            content: none;
          }
        }
        a {
          color: var(--color-text-weaker);
          @include hoverColor(var(--color-accent));
        }
      }
    }
  }
}
