@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image {
  margin-top: vw(80px);
  @include mq(tbl) {
    margin-top: pc(120px);
  }
  .inner {
    padding-inline: vw(16px);
    @include mq() {
      padding-inline: unset;
      display: flex;
      flex-direction: row-reverse;
      position: relative;
    }
    .image {
      @include mq() {
        flex: 0 1 auto;
        width: 50%;
        text-align: left;
      }
      img {
        @include image-radius;
        @include gsap01;
        @include mq(tbl) {
          // max-width: 660px;
        }
      }
    }
    .text {
      margin-top: vw(40px);
      @include mq() {
        margin-top: unset;
        padding-left: calc((100vw - var(--width-container) - 15px) / 2);
        flex: 0 1 auto;
        width: 50%;
        padding-right: pc(100px);
      }
      h3 {
        @include heading-xsmall;
        color: var(--color-accent);
        text-transform: uppercase;
      }
      .copy {
        h4 {
          margin-top: vw(10px);
          @include heading-medium;
          @include mq(tbl) {
            margin-top: pc(5px);
          }
        }
      }
      .desc {
        margin-top: vw(35px);
        @include mq(tbl) {
          margin-top: pc(55px);
        }
      }
      .button {
        margin-top: vw(35px);
        text-align: left;
        @include mq(tbl) {
          margin-top: pc(55px);
        }
        a {
          text-align: center;
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq() {
        flex-direction: row;
      }
      .text {
        @include mq() {
          padding-left: pc(100px);
          padding-right: calc((100vw - var(--width-container) - 15px) / 2);
        }
      }
      .image {
        @include mq() {
          text-align: right;
        }
      }
      /*
      .text {
        @include mq() {
          text-align: right;
        }
        .desc {
          @include mq() {
            text-align: left;
          }
        }
        .button {
          @include mq() {
            text-align: right;
          }
        }
      }
      */
    }
  }
  &.is-active {
    .inner {
      .image {
        img {
          @include gsap01-active;
        }
      }
    }
  }
}
