@charset "UTF-8";

@import "mixins";

.navigation.localnav_sub {
  margin-top: vw(40px);
  @include mq(tbl) {
    margin-top: pc(60px);
  }
  .navigation.archivenav + & {
    margin-top: vw(20px);
    @include mq(tbl) {
      margin-top: pc(30px);
    }
  }
  .inner {
    @include container;
    ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      li {
        margin-right: vw(10px);
        margin-bottom: vw(10px);
        white-space: nowrap;
        @include mq (tbl) {
          margin-right: pc(15px);
          margin-bottom: pc(15px);
        }
        &.active,&.current_page_item {
          a {
            background-color: var(--color-brand);
          }
        }
        a {
          display: inline-block;
          background-color: var(--color-surface);
          color: var(--color-text);
          font-size: vw(11px);
          padding: vw(8px) vw(12px);
          border-radius: var(--border-radius-pill);
          text-decoration: none;
          font-weight: var(--font-weight-bold);
          @include hoverBGColor(var(--color-brand));
          @include heading;
          @include mq(tbl) {
            font-size: pc(13px);
            padding: pc(8px) pc(16px);
          }
        }
      }
    }
  }
}
