@charset "UTF-8";

@import "mixins";

.component.type_heading {
  margin-top: vw(100px);
  @include mq(tbl) {
    margin-top: pc(140px);
  }
  &.large {
    .inner {
      @include container;
      .heading {
        @include heading;
        padding-block: 0 vw(6px);
        border-bottom: vw(1px) solid var(--color-border-heading);
        position: relative;
        font-size: vw(26px);
        line-height: var(--line-height-xsmall);
        word-break: auto-phrase;
        @include mq(tbl) {
          padding: 0 0 pc(12px) 0;
          font-size: pc(36px);
          border-width: pc(1px)
        }
      }
    }
  }
  &.medium {
    .inner {
      @include container;
      .heading {
        @include headingMedium;
      }
    }
  }
  .anchor + & {
    // margin-top: 20px;
    @include mq(tbl) {
      // margin-top: 45px;
    }
  }
}
