@charset "UTF-8";

@import "mixins";

.component.type_member {
  margin-top: vw(40px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    @include grid(2,vw(40px),vw(16px));
    @include mq(tbl) {
      @include grid(4,pc(75px),pc(26px));
    }
    .unit {
      display: grid;
      grid-template-rows: subgrid;
      gap: 0px 0px;
      .photo {
        img {
          aspect-ratio: var(--aspect-ratio-member);
          object-fit: cover;
          border-radius: vw(7px);
          @include mq(tbl) {
            border-radius: pc(7px);
          }
        }
      }
      .profile {
        margin-top: vw(24px);
        @include heading;
        word-break: auto-phrase;
        font-size: vw(20px);
        line-height: var(--line-height-small);
        @include mq(tbl) {
          margin-top: pc(32px);
          font-size: pc(24px);
        }
      }
      h3 {
        margin-top: vw(15px);
        @include heading;
        text-align: right;
        font-size: vw(20px);
        line-height: var(--line-height-xsmall);
        color: var(--color-accent);
        @include mq(tbl) {
          margin-top: pc(15px);
          font-size: pc(24px);
        }
        i {
          font-weight: 400;
          display: block;
          font-family: var(--font-family-en);
          font-size: vw(11px);
          margin-top: vw(3px);
          text-transform: uppercase;
          line-height: 1;
          color: var(--color-text);
          @include mq(tbl) {
            font-size: pc(14px);
            margin-top: pc(5px);
          }
        }
      }
      .position {
        margin-top: vw(15px);
        font-size: vw(10px);
        text-align: right;
        line-height: 1;
        @include mq(tbl) {
          margin-top: pc(20px);
          font-size: pc(13px);
        }
      }
      .desc {
        border-top: vw(.5px) solid var(--color-border);
        padding-top: vw(16px);
        margin-top: vw(16px);
        font-size: vw(12px);
        line-height: var(--line-height-medium);
        @include mq(tbl) {
          margin-top: pc(20px);
          padding-top: pc(20px);
          border-top-width: pc(.5px);
          font-size: pc(14px);
        }
        p {
          padding-bottom: 1em;
          &:last-child {
            padding-bottom: unset;
          }
        }
      }
      .button {
        text-align: right;
        margin-top: vw(24px);
        @include mq(tbl) {
          margin-top: pc(24px);
        }
        .p_button_b {
          min-width: unset;
          @include mq(tbl) {
            min-width: pc(160px);
          }
        }
      }
    }
  }
}
