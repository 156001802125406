@charset "UTF-8";

@import "mixins";

.static.image_link {
  margin-top: var(--margin-top-medium);
  padding-bottom: var(--space-large);
  @include mq(tbl) {
    margin-top: var(--margin-top-large);
    padding-bottom: 0;
  }
  .inner {
    @include container;
    .unit {
      background-size: 90% auto;
      background-position: top left;
      background-repeat: no-repeat;
      padding: 150px 0 0 45px;
      @include mq(tb) {
        background-size: 69% auto;
        padding: 0 0 0 61%;
        position: relative;
        min-height: 350px;
      }
      @include mq(tbl) {
        min-height: 505px;
      }
      .box {
        background-color: var(--color-bg);
        border-left: 1px solid var(--color-deep);
        transform: translateY(var(--space-large));
        position: relative;
        padding: var(--space-large) 0 var(--space-xxsmall) var(--space-large);
        @include mq(tb) {
          transform: translateY(0);
          position: absolute;
          right: 0;
          bottom: 0;
          width: 380px;
          padding: var(--space-xlarge) var(--space-small) var(--space-small) var(--space-xlarge);
        }
        &:before {
          @include pd;
          top: 0;
          left: 0;
          height: 1px;
          width: 100%;
          background-color: var(--color-deep);
        }
        h3 {
          font-size: var(--font-size-large);
          line-height: var(--line-height-medium);
          font-weight: var(--font-weight-heading);
          font-family: var(--font-family-heading);
          @include mq(tbl) {
            font-size: var(--font-size-xlarge);
          }
        }
        p {
          font-size: var(--font-size-xsmall);
          line-height: var(--line-height-medium);
          margin-top: var(--space-small);
          @include mq(tbl) {
            margin-top: var(--space-medium);
            font-size: var(--font-size-small);
          }
        }
        ul {
          margin-top: var(--space-medium);
          li {
            font-size: var(--font-size-xsmall);
            line-height: var(--line-height-medium);
            margin-top: var(--space-xsmall);
            @include mq(tbl) {
              margin-top: var(--space-small);
              font-size: var(--font-size-small);
            }
            a {
              position: relative;
              display: block;
              padding-left: var(--space-medium);
              color: var(--color-brand);
              text-decoration: underline;
              @include mq(tbl) {
                padding-left: var(--space-large);
                &:hover {
                  text-decoration: none;
                }
              }
              &:before {
                @include pd;
                left: 0;
                top: 7px;
                background: center / contain no-repeat var(--arrow-right);
                width: 8px;
                height: 8px;
                @include mq(tbl) {
                  width: 12px;
                  height: 12px;
                  top: 5px;
                }
              }
            }
          }
        }
        .link {
          margin-top: var(--space-medium);
          text-align: right;
          @include mq(tbl) {
            margin-top: var(--space-large);
          }
          a {
            text-align: center;
          }
        }
      }
    }
  }
}
