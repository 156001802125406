@charset "UTF-8";

@import "mixins";

.component.type_text_image {
  margin-top: vw(50px);
  @include mq() {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    @include mq(tb) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: vw(24px);
      @include mq(tb) {
        width: var(--col-width-text);
        margin-top: unset;
      }
    }
    .image {
      @include mq(tb) {
        width: var(--col-width-image);
        margin-top: unset;
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tb) {
        flex-direction: row-reverse;
      }
    }
  }
}
