@charset "UTF-8";

@import "mixins";

.component.type_panel {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  &.set2 {
    .inner {
      @include grid(1,vw(40px),0);
      @include mq(tbl) {
        @include grid(2,pc(60px),pc(60px));
      }
    }
  }
  &.set3 {
    .inner {
      @include grid(2,vw(30px),vw(25px));
      @include mq(tbl) {
        @include grid(3,pc(50px),pc(25px));
      }
    }
  }
  &.set4 {
    .inner {
      @include grid(2,vw(30px),vw(25px));
      @include mq(tbl) {
        @include grid(4,pc(40px),pc(20px));
      }
    }
  }
  .inner {
    @include container;
    .unit {
      display: grid;
      grid-template-rows: subgrid;
      grid-row: span 4;
      gap: 0;
      @include mq(tb) {
        max-width: pc(460px);
        flex: 1;
        position: relative;
      }
      .photo {
        @include mq(tb) {
          display: flex;
          align-items: center;
        }
        a {
          @include mq(tbl) {
            @include hoverOp;
          }
        }
        img {
          border-radius: vw(7px);
          @include mq(tbl) {
            border-radius: pc(7px);
          }
        }
        + .desc {
          margin-top: vw(24px);
          @include mq(tbl) {
            margin-top: pc(32px);
          }
        }
      }
      .title {
        margin-top: vw(24px);
        @include mq(tbl) {
          margin-top: pc(32px);
        }
        h3 {
          @include heading;
          font-size: vw(20px);
          line-height: var(--line-height-small);
          @include mq(tbl) {
            font-size: pc(24px);
          }
          a {
            text-decoration: none;
            color: var(--color-text);
            @include mq(tbl) {
              @include hoverOp;
            }
          }
        }
      }
      .desc {
        margin-top: vw(16px);
        font-size: vw(14px);
        line-height: var(--line-height-large);
        @include mq(tbl) {
          font-size: pc(16px);
          margin-top: pc(24px);
        }
      }
      .button {
        margin-top: vw(16px);
        text-align: right;
        @include mq(tbl) {
          margin-top: pc(24px);
        }
        .p_button {
          min-width: vw(120px);
          @include mq {
            min-width: pc(170px);
          }
        }
      }
    }
  }
}
