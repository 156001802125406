@charset "UTF-8";
@import "mixins";

.contactus {
  @include container;
  margin-top: vw(100px);
  @include mq(tbl) {
    margin-top: pc(170px);
  }
  .inner {
    background-color: var(--color-accent);
    color: var(--color-text-on-accent);
    padding: vw(30px);
    border-radius: vw(17px);
    @include mq(tbl) {
      padding: pc(60px) pc(40px) pc(40px) pc(65px);
      border-radius: pc(17px);
      display: grid;
      column-gap: 10%;
      grid-template:
        "title text" auto
        "title buttons" auto
        / 30% 60%;
    }
    .title {
      @include mq(tbl) {
        grid-area: title;
      }
      h2 {
        @include heading-small;
      }
      p {
        margin-top: vw(1px);
        margin-left: vw(2px);
        font-size: vw(10px);
        line-height: var(--line-height-xsmall);
        font-family: var(--font-family-en);
        text-transform: uppercase;
        opacity: .5;
        @include mq(tbl) {
          font-size: pc(12px);
          margin-top: pc(5px);
          margin-left: pc(2px);
        }
      }
    }
    .text {
      margin-top: vw(30px);
      font-size: vw(14px);
      line-height: var(--line-height-small);
      font-weight: var(--font-weight-bold);
      @include mq(tbl) {
        grid-area: text;
        text-align: right;
        margin-top: pc(9px);
        font-size: pc(16px);
      }
    }
    .buttons {
      margin-top: vw(30px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: vw(10px);
      @include mq(tbl) {
        grid-area: buttons;
        margin-top: pc(50px);
        gap: pc(27px);
        grid-template-columns: auto auto;
        justify-content: end;
      }
      li {
        a {
          min-width: 100%;
          padding-inline: vw(10px);
          font-size: vw(12px);
          gap: vw(6px);
          &:not([href^="tel:"]) {
            @include heading;
          }
          @include mq(tbl) {
            padding-inline: pc(40px);
            font-size: pc(16px);
            gap: pc(10px);
            min-width: pc(214px);
          }
          
        }
      }

    }
  }
}
.mynavi {
  margin-top: vw(100px);
  background-color: var(--color-brand);
  padding-block: vw(80px);
  @include mq(tbl) {
    margin-top: pc(170px);
    padding-block: pc(140px);
  }
  .inner {
    @include container;
    display: grid;
    row-gap: vw(40px);
    grid-template:
      "logo" auto
      "title" auto
      / 1fr;
    @include mq(tbl) {
      row-gap: unset;
      align-items: center;
      grid-template:
        "title logo" auto
        / 1fr 1fr;
    }
    .title {
      grid-area: title;
      text-align: center;
      @include mq(tbl) {
        padding-left: pc(50px);
      }
      h2 {
        @include heading-medium;
      }
      p {
        margin-top: vw(30px);
        @include mq(tbl) {
          margin-top: pc(30px);
        }
      }
    }
    .logo {
      grid-area: logo;
      text-align: center;
      img {
        width: vw(140px);
        @include mq(tbl) {
          width: pc(240px);
        }
      }
    }
  }
}
.about_vision {
  margin-top: vw(80px);
  @include mq(tbl) {
    margin-top: pc(120px);
    text-align: center;
  }
  h3 {
    @include heading-xsmall;
    color: var(--color-accent);
    text-transform: uppercase;
  }
  .copy {
    h4 {
      margin-top: vw(10px);
      @include heading-medium;
      @include mq(tbl) {
        margin-top: pc(5px);
      }
    }
  }
  .desc {
    margin-top: vw(35px);
    font-size: vw(14px);
    line-height: var(--line-height-xlarge);
    padding-bottom: 2em;
    text-align: left;
    @include mq(tbl) {
      font-size: pc(16px);
      margin-top: pc(55px);
      padding-left: var(--padding-text-pc);
      padding-right: var(--padding-text-pc);
    }
  }
}
.about_outline {
  position: relative;
  padding: vw(20px) vw(20px);
  @include mq(tbl) {
    padding: pc(40px) pc(60px);
  }
  &::before, &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: var(--color-surface);
    border-radius: vw(17px);
    z-index: -1;
    mix-blend-mode: soft-light;
    @include mq(tbl) {
      border-radius: pc(17px);
    }
  }
  &::before {
    z-index: -2;
    mix-blend-mode: unset;
  }
  table {
    width: 100%;
    th,td {
      border-top: vw(1px) solid var(--color-border-weak);
      vertical-align: top;
      padding-block: vw(16px);
      min-width: vw(100px);
      font-size: vw(14px);
      line-height: var(--line-height-medium);
      @include mq(tbl) {
        border-top-width: pc(1px);
        font-size: pc(16px);
        padding-block: pc(16px);
        min-width: pc(100px);
      }
    }
    th {
      font-weight: var(--font-weight-bold);
      white-space: nowrap;
    }
    tr {
      &:first-child {
        th, td {
          border-top: none;
        }
      }
    }
  }
}
.component.type_wysiwyg:has(.about_message) {
  @include mq(tbl) {
    @include container;
  }
}
.about_message {
  margin-top: vw(100px);
  position: relative;
  height: vw(613px);
  @include mq(tbl) {
    margin-top: pc(200px);
    height: pc(917px);
  }
  li {
    position: absolute;
    &.is-active {
      img {
        opacity: 1;
        translate: 0 0;
        filter: drop-shadow(0px 4.253px 9.114px rgba(0, 0, 0, 0.10)) drop-shadow(0px 15.797px 15.797px rgba(0, 0, 0, 0.09)) drop-shadow(0px 35.847px 21.872px rgba(0, 0, 0, 0.05));
      }
    }
    img {
      @include gsap01;
      width: 100%;
    }
    &.comment_14 {
      top: vw(14px);
      left: vw(16px);
      width: vw(120px);
      z-index: 1;
      @include mq(tbl) {
        top: pc(41px);
        left: pc(1px);
        width: pc(240px);
      }
    }
    &.comment_05 {
      top: vw(0px);
      left: vw(189px);
      width: vw(150px);
      z-index: 10;
      @include mq(tbl) {
        top: pc(0px);
        left: pc(507px);
        width: pc(300px);
      }
    }
    &.comment_08 {
      top: vw(67px);
      left: vw(76px);
      width: vw(126px);
      z-index: 7;
      @include mq(tbl) {
        top: pc(80px);
        left: pc(206px);
        width: pc(252px);
      }
    }
    &.comment_06 {
      top: vw(90px);
      left: vw(220px);
      width: vw(137px);
      z-index: 9;
      @include mq(tbl) {
        top: pc(164px);
        left: pc(647px);
        width: pc(273px);
      }
    }
    &.comment_09 {
      top: vw(150px);
      left: vw(157px);
      width: vw(136px);
      z-index: 6;
      @include mq(tbl) {
        top: pc(233px);
        left: pc(332px);
        width: pc(272px);
      }
    }
    &.comment_10 {
      top: vw(204px);
      left: vw(16px);
      width: vw(155px);
      z-index: 5;
      @include mq(tbl) {
        top: pc(266px);
        left: pc(0px);
        width: pc(310px);
      }
    }
    &.comment_11 {
      top: vw(210px);
      left: vw(234px);
      width: vw(123px);
      z-index: 4;
      @include mq(tbl) {
        top: pc(327px);
        left: pc(646px);
        width: pc(246px);
      }
    }
    &.comment_07 {
      top: vw(278px);
      left: vw(85px);
      width: vw(215px);
      z-index: 8;
      @include mq(tbl) {
        top: pc(409px);
        left: pc(253px);
        width: pc(430px);
      }
    }
    &.comment_02 {
      top: vw(375px);
      left: vw(16px);
      width: vw(145px);
      z-index: 13;
      @include mq(tbl) {
        top: pc(506px);
        left: pc(2px);
        width: pc(290px);
      }
    }
    &.comment_04 {
      top: vw(404px);
      left: vw(239px);
      width: vw(118px);
      z-index: 11;
      @include mq(tbl) {
        top: pc(534px);
        left: pc(657px);
        width: pc(236px);
      }
    }
    &.comment_01 {
      top: vw(438px);
      left: vw(135px);
      width: vw(106px);
      z-index: 14;
      @include mq(tbl) {
        top: pc(641px);
        left: pc(323px);
        width: pc(212px);
      }
    }
    &.comment_03 {
      top: vw(468px);
      left: vw(234px);
      width: vw(123px);
      z-index: 12;
      @include mq(tbl) {
        top: pc(663px);
        left: pc(677px);
        width: pc(246px);
      }
    }
    &.comment_13 {
      top: vw(555px);
      left: vw(208px);
      width: vw(104px);
      z-index: 2;
      @include mq(tbl) {
        top: pc(767px);
        left: pc(480px);
        width: pc(208px);
      }
    }
    &.comment_12 {
      top: vw(507px);
      left: vw(16px);
      width: vw(197px);
      z-index: 3;
      @include mq(tbl) {
        top: pc(767px);
        left: pc(12px);
        width: pc(394px);
      }
    }
  }
}