@charset "UTF-8";

@import "reset";

@import "mixins";

@import url("https://use.typekit.net/iks0ebh.css");

$responsive-debug-mode: false;

* {
   box-sizing: border-box;
}
*:before,
*:after {
   box-sizing: border-box;
}
*:focus {
outline: none;
}
html {
  font-size: vw(14px);
  height: 100%;
  // scroll-padding-top: vw(60px);
  @include mq(){
    font-size: pc(16px);
    // scroll-padding-top: unset;
  }
}
body {
  color: var(--color-text);
  background-color: var(--color-bg);
  font-family: var(--font-family);
  font-weight: normal;
  font-feature-settings: var(--font-feature-settings);
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @if $responsive-debug-mode {
    &:after {
      content: 'min';
      position: fixed;
      left: 10px;
      top: 10px;
      font-size: 12px;
      background-color: red;
      color: white;
      z-index: 1000;
      display: inline-block;
      padding: 4px;
      @include mq(sp) {
        content: 'sp';
      }
      @include mq(spl) {
        content: 'sp landscape';
      }
      @include mq(tb) {
        content: 'tablet';
      }
      @include mq(tbl) {
        content: 'tablet landscape';
      }
      @include mq(pc) {
        content: 'pc';
      }
    }
  }
  &.fixed {
    position: fixed;
    width: 100%;
  }
}

.red {
  color: var(--color-text-error);
}
strong {
  font-weight: var(--font-weight-bold);
}
label {
  cursor: pointer;
}
input,select,textarea,label,a {
  outline: none;
}
a {
  color: var(--color-text-link);
  text-decoration: none;
  outline: none;
  cursor: pointer;
  &[href^="tel:"] {
    color: inherit;
  }
  &:focus {
    outline: none;
  }
}
abbr {
  text-decoration: none;
}
button {
  outline: none;
  cursor: pointer;
}
sup,
sub {
  height: 0;
  line-height: 1;
  vertical-align: baseline;
  position: relative;
  font-size: 0.75em;
}
sup {
  bottom: .75ex;
}
sub {
  top: .5ex;
}
img {
  // width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  @include mq(tbl) {
    // width: auto;
  }
}
.hidden-over-tbl {
  @include mq(tbl) {
    display: none;
  }
}
.hidden-over-tb {
  @include mq(tb) {
    display: none;
  }
}
.hidden-over-sp {
  @include mq(sp) {
    display: none;
  }
}
.hidden-sp {
  @media (max-width: 374px) {
    display: none;
  }
}
.hidden-tb {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden-tbl {
  @media (max-width: 1079px) {
    display: none;
  }
}

.anchor {
  position: relative;
  top: 0;
  display:block;
  visibility:hidden;
  color: transparent;
  &_sub {
    position: relative;
    top: 0;
    display:block;
    visibility:hidden;
    color: transparent;
    @include mq(tbl) {
      top: -100px;
    }
  }
}
.svg_def {
  visibility:hidden;
  width: 0;
  height: 0;
  position: absolute;
}
.container {
  @include container;
}
main {
  transition: opacity var(--transition-speed) var(--bezier);
  &:not(:has(:first-child.component.type_extendedtitle)) {
    padding-top: vw(60px);
    @include mq(tbl) {
      padding-top: pc(100px);
    }
    &:has(.top_mv) {
      padding-top: 0;
    }
  }
  &:has(.top_mv) {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
}
.p_button {
  display: inline grid;
  text-decoration: none !important;
  position: relative;
  line-height: 1;
  padding: vw(15px) vw(35px);
  min-width: vw(215px);
  border-radius: vw(7px);
  color: var(--color-text-on-accent) !important;
  background: var(--color-accent);
  font-size: vw(14px);
  font-weight: 700;
  font-family: var(--font-family-en);
  justify-content: center;
  grid-template-columns: auto auto;
  gap: vw(11px);
  box-shadow: 0px 45px 13px 0px rgba(0, 0, 0, 0.00), 0px 29px 12px 0px rgba(0, 0, 0, 0.01), 0px 16px 10px 0px rgba(0, 0, 0, 0.05), 0px 7px 7px 0px rgba(0, 0, 0, 0.09), 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  @include mq(tbl) {
    font-size: pc(16px);
    min-width: pc(215px);
    border-radius: pc(7px);
    padding: pc(20px) pc(15px);
    gap: pc(11px);
    transition: all var(--bezier) var(--transition-speed-fast);
    @include hoverBGColor(var(--color-third));
  }
  &:before {
    content: '';
    display: block;
    background: center / contain no-repeat var(--arrow-button-on-accent);
    width: vw(6px);
    height: 1lh;
    @include mq(tbl) {
      width: pc(6px);
    }
  }
  &[target="_blank"] {
    &:before {
      background-image: var(--arrow-newwin);
      width: vw(12px);
      height: 1lh;
      @include mq(tbl) {
        width: pc(12px);
      }
    }
  }
  &[href^="tel:"] {
    &:before {
      background-image: var(--icon-tel) !important;
      width: vw(13px);
      height: 1lh;
      @include mq(tbl) {
        width: pc(13px);
      }
    }
  }
  &.cv {
    padding: vw(24px) vw(32px);
    font-size: vw(16px);
    @include heading;
    @include mq(tbl) {
      padding: pc(24px) pc(32px);
      min-width: pc(300px);
      font-size: pc(20px);
    }
  }
  &.reverse {
    color: var(--color-text) !important;
    background: var(--color-surface);
    @include hoverBGColor(var(--color-brand));
    &:before {
      background-image: var(--arrow-button);
    }
    &[target="_blank"] {
      &:before {
        background-image: var(--arrow-newwin);
      }
    }
  }
}
.p_button_b {
  display: inline grid;
  text-decoration: none !important;
  position: relative;
  line-height: 1;
  padding: vw(15px) vw(35px);
  min-width: vw(215px);
  border-radius: vw(7px);
  color: var(--color-text) !important;
  background: var(--color-surface);
  border: vw(1px) solid var(--color-border-strong);
  font-size: vw(14px);
  font-weight: 700;
  font-family: var(--font-family-en);
  justify-content: center;
  grid-template-columns: auto auto;
  gap: vw(11px);
  @include mq(tbl) {
    border-width: pc(1px);
    font-size: pc(16px);
    min-width: pc(215px);
    border-radius: pc(7px);
    padding: pc(15px) pc(15px);
    gap: pc(11px);
    transition: all var(--bezier) var(--transition-speed-fast);
    @include hoverBGColor(var(--color-brand));
  }
  &:before {
    content: '';
    display: block;
    background: center / contain no-repeat var(--arrow-button);
    width: vw(6px);
    height: 1lh;
    @include mq(tbl) {
      width: pc(6px);
    }
  }
}

#wrapper {
  transition: opacity var(--transition-speed) var(--bezier);
  
  header + & {
    padding-top: var(--height-header);
    @include mq(tbl) {
      padding-top: var(--height-header-pc);
    }
  }
  .toppage & {
    padding-top: 0;
  }
  .sink & {
    opacity: .5;
    @include mq(tbl) {
      opacity: 1;
    }
  }
  .contentsInner {
    margin-top: var(--space-medium);
    @include mq(tbl) {
      display: flex;
      flex-direction: row-reverse;
      gap: 50px;
      @include container;
      padding-top: var(--space-xxlarge);
      padding-bottom: var(--space-xxxlarge);
      margin-top: 0;
    }
    main {
      padding-top: 0;
      @include mq(tbl) {
        flex: 1;
      }
      > *:first-child {
        margin-top: 0;
      }
      > .component.type_pagetitle .inner {
        padding-top: 0;
      }
  
    }
    #sidebar {
      @include mq(tbl) {
        width: 250px;
      }
    }
  }
  
}
.bgwrapper {
  background-position: center;
  background-size: cover;
  > *:first-child {
    margin-top: 0 !important;
  }
}
.path-a {
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}
.path-blue {
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}
.path-yellow {
  width: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}
.coverOut {
  position: relative;
  display: inline-block;
  &::before {
    content: "";
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background-color: var(--color-bg);
    z-index: 20;
    transition: transform 0.5s cubic-bezier(1, 0, 0, 1) 0s;
    transform-origin: right;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-third);
    z-index: 19;
    transition: transform 0.5s cubic-bezier(1, 0, 0, 1) .3s;
    transform-origin: right;
  }
  &.is-active {
    &::before {
      transform: scaleX(0);
    }
    &::after {
      transform: scaleX(0);
    }
  }
  &.color-third {
    background-color: var(--color-third);
    color: var(--color-text-on-accent);
    &::before {
      background-color: var(--color-surface);
    }
  }
}
#cover {
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    height: 100%;
    width: 100%;
    background-color: var(--color-accent-weak);
    transition: transform .7s cubic-bezier(.16,.82,.27,.97) .6s;
    overflow: hidden;
  }
  .logo {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 10001;
    transition: opacity .3s linear, transform 1.2s linear;
    transform-origin: center center;
    img {
      width: 280px;
      transform: translate(-50%,-50%);
      @include mq(tbl) {
        width: 300px;
      }
    }
    #logo_loading {
      width: 230px;
      transform: translate(-50%,-50%);
      @include mq(tbl) {
        width: 230px;
      }
      path {
        stroke:transparent;
        fill:transparent;
        stroke-dasharray: 290;
        stroke-dashoffset: 290;
        stroke-width: 1;
        stroke: var(--color-text-on-accent);
        animation: kf-logo cubic-bezier(.79,.05,.21,.89) 1.2s alternate infinite;
      }
      @keyframes kf-logo {
        0% {
          stroke-dashoffset: 290;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
    }
  }
  .wf-active &, .wf-active-alt & {
    .bg {
      transform: translateY(-100%);
    }
    .logo {
      opacity: 0;
      transform: translateY(-50%);
    }
  }
}

//default
@import "common/header";
@import "common/footer";
@import "common/sidebar";
@import "common/storelist";
@import "component/index";
@import "navigation/index";
@import "static/index";
@import "swiper";
@import "lenis";
@import "top";
@import "uniq";