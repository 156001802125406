@charset "UTF-8";

@import "mixins";

.navigation.localnav {
  padding-inline: vw(16px);
  @include mq(tbl) {
    padding-inline: pc(35px);
    display: flex;
    justify-content: flex-end;
  }
  .inner {
    background-color: #ececec;
    border-radius: vw(10px);
    @include mq(tbl) {
      border-radius: pc(10px);
      padding-inline: pc(6px);
    }
    .overflow {
      overflow: auto;
      @include mq(tbl) {
        overflow: visible;
      }
      ul {
        display: flex;
        justify-content: flex-start;
        position: relative;
        @include mq(tbl) {
          justify-content: flex-end;
          flex-wrap: wrap;
        }
        li {
          font-size: vw(12px);
          @include heading;
          white-space: nowrap;
          @include mq(tbl) {
            font-size: pc(14px);
            &:last-child {
              padding-right: 0;
            }
          }
          &.active,&.current_page_item, &:hover {
            a {
              &::after {
                content: "";
                display: block;
                position: absolute;
                height: vw(3px);
                bottom: vw(8px);
                left: vw(16px);
                right: vw(16px);
                background-color: var(--color-brand);
                @include mq(tbl) {
                  height: pc(4px);
                  bottom: pc(10px);
                  left: pc(16px);
                  right: pc(16px);
                }
              }
            }
          }
          a {
            display: block;
            position: relative;
            transition: color var(--transition-speed-fast) var(--bezier), opacity var(--transition-speed-fast) var(--bezier);
            padding: vw(16px);
            color: var(--color-text);
            letter-spacing: 0;
            @include mq(tbl) {
              padding: pc(20px);
            }
          }
        }
      }
    }
  }
}
