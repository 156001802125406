@charset "UTF-8";

@import "mixins";

.component.type_catchcopy {
  margin-top: vw(80px);
  @include mq(tbl) {
    margin-top: pc(120px);
  }
  &.withbg {
    padding-bottom: var(--padding-bottom-catchcopy);
    background-repeat: no-repeat;
    background-position: right bottom;
    @include mq(tbl) {
      padding-bottom: var(--padding-bottom-catchcopy-pc);
    }
    + * {
      margin-top: 0;
      @include mq(tbl) {
        margin-top: 0;
      }
    }
  }
  .inner {
    @include container;
    @include mq() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: pc(60px);
      grid-row-gap: 0;
    }
    .heading {
      @include mq() {
        grid-area: 1 / 1 / 2 / 3;
      }
      h3 {
        @include heading-xsmall;
        color: var(--color-accent);
        text-transform: uppercase;
      }
    }
    .copy {
      @include mq() {
        grid-area: 2 / 1 / 3 / 2;
      }
      h4 {
        margin-top: vw(10px);
        @include heading-medium;
        @include mq(tbl) {
          margin-top: pc(5px);
        }
      }
      .button {
        display: none;
        margin-top: pc(55px);
        @include mq() {
          display: block;
        }
        a {
          text-align: center;
        }
      }
    }
    .text {
      margin-top: vw(35px);
      @include mq() {
        grid-area: 2 / 2 / 3 / 3;
        margin-top: pc(10px);
      }
    }
    .button {
      margin-top: vw(35px);
      @include mq() {
        display: none;
      }
      a {
        text-align: center;
      }
    }
  }
}
