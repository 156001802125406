@charset "UTF-8";

@import "mixins";

.navigation.pagenate_detail {
  @include container;
  margin-top: vw(60px);
  @include mq(tbl) {
    margin-top: pc(80px);
  }
  .inner {
    position: relative;
    display: grid;
    grid-template: 
      "prev back next" auto
      / 1fr auto 1fr;
    @include mq(tbl) {
      justify-content: center;
      gap: pc(30px);
    }
    span {
      display: inline-block;
      a {
        position: relative;
        text-align: center;
        min-width: vw(80px);
        padding-inline: vw(20px);
        @include mq(tbl) {
          min-width: pc(120px);
        }
        svg {
          display: none;
        }
      }
      &.prev {
        grid-area: prev;
        @include mq(tbl) {
          justify-self: end;
        }
        a {
          &:before {
            rotate: z 180deg;
          }
        }
      }
      &.back {
        grid-area: back;
        a {
          gap: 0;
          &:before {
            content: none;
          }
        }
      }
      &.next {
        grid-area: next;
        justify-self: end;
        direction: rtl;
        @include mq(tbl) {
          justify-self: start;
        }
      }
    }
  }
}
