@charset "UTF-8";

@import "mixins";


.component.type_pagetitle {
  .m_breadcrumbs + & {
    margin-top: 10px;
    @include mq(tbl) {
      margin-top: 18px;
    }
  }
  .inner {
    @include container;
    position: relative;
    padding-top: vw(90px);
    @include mq(tbl) {
      padding-top: pc(130px);
    }
    .title {
      font-size: vw(44px);
      line-height: var(--line-height-xsmall);
      @include heading;
      word-break: auto-phrase;
      @include mq(tbl) {
        font-size: pc(58px);
      }
    }
  }
}
