@charset "UTF-8";

@import "mixins";

.component.type_conversion {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--color-deep-weaker);
  padding-block: vw(100px);
  margin-top: vw(70px);
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  @include mq(tbl) {
    padding-block: pc(140px);
  }
  .inner {
    @include container;
    color: var(--color-text-on-accent);
    text-align: left;
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    .image {
      text-align: inherit;
      padding-bottom: vw(20px);
      margin-left: vw(2px);
      @include mq {
        padding-bottom: pc(20px);
        margin-left: pc(2px);
      }
      img {
        max-width: 50%;
        @include mq(tbl) {
          max-width: 25%;
        }
      }
    }
    .copy {
      h4 {
        @include heading-medium;
      }
    }
    .desc {
      margin-top: vw(24px);
      @include mq(tbl) {
        margin-top: pc(24px);
      }
    }
    .button {
      margin-top: vw(32px);
      @include mq(tbl) {
        margin-top: pc(48px);
      }
    }
  }
}
