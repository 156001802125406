@charset "UTF-8";

@import "mixins";

#sidebar {
  padding: var(--space-large) var(--space-medium) var(--space-xxxlarge);
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-surface);
  z-index: 1004;
  overflow-y: scroll;
  transform: translateY(calc(100% + 150px));
  transition: transform var(--transition-speed) var(--bezier);
  @include mq(tbl){
    padding: 0;
    text-align: center;
    position: static;
    width: auto;
    overflow-y: unset;
    transform: translateY(0);
    transition: none;
    z-index: 1;
  }
  &.is-show {
    transform: translateY(0);
  }
  .sidebar-header {
    background-color: var(--color-surface-weaker);
    position: relative;
    font-weight: var(--font-weight-medium);
    margin-top: var(--space-xlarge);
    padding: var(--space-small) var(--space-xsmall) var(--space-medium);
    font-size: var(--font-size-large);
    text-align: center;
    @include mq(tbl){
      padding: var(--space-xsmall) var(--space-xsmall) var(--space-small);
      font-size: var(--font-size-xsmall);
    }
    &:first-of-type {
      margin-top: 0;
    }
    &::before,&::after {
      @include pd;
      height: 2px;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color:var(--color-brand-weak);
    }
    &::after {
      width: 40px;
      background-color: var(--color-accent);
    }
    
  }
  .searchbox {
    margin-top: var(--space-xsmall);
    border: 1px solid var(--color-border-strong);
    border-radius: var(--border-radius-small);
    transition: border var(--transition-speed-fast) var(--bezier);
    &:has(input[type="text"]:focus) {
      border-color: var(--color-accent);
    }
    .searchbox-inner {
      display: flex;
      align-items: center;
      gap: var(--space-xsmall);
      padding-left: var(--space-small);
      padding-right: var(--space-small);
      .icon {
        img {
          width: 18px;
        }
      }
      input[type="text"] {
        border: none;
        padding-top: var(--space-medium);
        padding-bottom: var(--space-medium);
        width: 100%;
        font-size: var(--font-size-medium);
        flex: 1;
        @include mq(tbl){
          font-size: var(--font-size-xsmall);
          padding-top: var(--space-small);
          padding-bottom: var(--space-small);
        }
        &:focus {
          outline: none;
        }
      }
    }
  }
  .categories {
    ul {
      li {
        margin-top: var(--space-xsmall);
        a {
          text-align: left;
          border: 1px solid var(--color-border);
          display: flex;
          align-items: center;
          gap: var(--space-small);
          min-height: var(--space-xxlarge);
          border-radius: var(--border-radius-small);
          padding: var(--space-small) var(--space-small);
          line-height: var(--line-height-xsmall);
          font-size: var(--font-size-medium);
          color: var(--color-text);
          transition: border var(--transition-speed-fast) var(--bezier), background var(--transition-speed-fast) var(--bezier);
          @include mq(tbl){
            font-size: var(--font-size-small);
            padding: var(--space-xsmall) var(--space-small);
            &:hover {
              background-color: rgba(210, 75, 15, .05);
              border-color: var(--color-accent);
            }
          }
          i {
            display: flex;
            width: 24px;
            height: 24px;
            text-align: center;
            align-items: center;
            justify-content: center;
            img {
              max-width: 24px;
              max-height: 24px;
            }
          }
        }
      }
    }
  }
  .catalog {
    margin-top: var(--space-xsmall);
    a {
      display: block;
      border: 1px solid var(--color-border);
      background-color: var(--color-surface-weaker);
      border-radius: var(--border-radius-small);
      text-align: center;
      padding: var(--space-medium);
      color: var(--color-text);
      transition: border var(--transition-speed-fast) var(--bezier);
      @include mq(tbl){
        &:hover {
          border-color: var(--color-accent);
        }
      }
      h3 {
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-large);
        line-height: var(--line-height-xsmall);
        @include mq(tbl){
          font-size: var(--font-size-medium);
        }
      }
      .image {
        margin-top: var(--space-xsmall);
        img {
          max-width: 200px;
          max-height: 200px;
        }
      }
      p {
        font-size: var(--font-size-xsmall);
        color: var(--color-text-weaker);
        @include mq(tbl){
          font-size: var(--font-size-xxsmall);
        }
      }
    }
  }
}
#sidebar-menu-button {
  position: fixed;
  bottom: var(--space-xlarge);
  right: var(--space-large);
  background: top / 64px auto no-repeat url(/images/icon_sidebar.svg);
  width: 64px;
  height: 64px;
  border-radius: var(--border-radius-circle);
  z-index: 2000;
  transition: background-position var(--transition-speed) var(--bezier);
  &.is-active {
    background-position: center bottom;
  }
  @include mq(tbl){
    display: none;
  }
  span {
    display: none;
  }
}