@charset "UTF-8";

@import "mixins";

.component.type_extendedtitle {
  position: relative;
  background-color: var(--color-extendedtitle-bg);
  overflow: hidden;
  min-height: vw(500px);
  padding-top: vw(30px);
  @include mq() {
    overflow: unset;
    min-height: pc(700px);
    padding-top: pc(80px);
  }
  .inner {
    display: flex;
    flex-direction: column-reverse;
    z-index: 3;
    position: relative;
    @include mq() {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: pc(120px);
    }
    .text {
      padding: vw(50px) vw(16px);
      @include mq() {
        padding: unset;
        padding-left: calc((100vw - var(--width-container) - 15px) / 2);
      }
      span {
        margin-top: vw(5px);
        margin-left: vw(2px);
        display: block;
        font-size: vw(18px);
        line-height: var(--line-height-xsmall);
        font-family: var(--font-family-en);
        text-transform: uppercase;
        @include mq(tbl) {
          font-size: pc(24px);
          margin-top: pc(5px);
          margin-left: pc(2px);
        }
      }
      h1 {
        @include heading-medium;
      }
    }
    .image {
      translate: vw(20px);
      padding-top: vw(30px);
      @include mq() {
        text-align: left;
        translate: 0;
        padding-top: pc(40px);
      }
      img {
        aspect-ratio: 56 / 40;
        object-fit: cover;
        @include image-radius;
        @include gsap01;
        @include mq(tbl) {
          max-width: 560px;
        }
      }
    }
  }
  .path-a {
    bottom: vw(20px);
    @include mq() {
      bottom: pc(-100px);
    }
  }
  &.is-active {
    .inner {
      .image {
        img {
          @include gsap01-active;
        }
      }
    }
  }
}


