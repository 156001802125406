@charset "UTF-8";

@import "mixins";

.component.type_text_lead {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    .text {
      p {
        font-size: vw(44px);
        line-height: var(--line-height-xsmall);
        @include heading;
        // word-break: auto-phrase;
        box-decoration-break: clone;
        //display: inline;
        @include mq(tbl) {
          font-size: pc(58px);
        }
        &.left {
          text-align: left;
        }
        &.center {
          text-align: center;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }
}
