@charset "UTF-8";

@import "mixins";

.component.type_text_map {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
    }
    .text {
      margin-top: var(--space-large);
      @include mq(tbl) {
        width: var(--col-width-text);
        margin-top: 0;
      }
    }
    .map {
      @include mq(tbl) {
        width: var(--col-width-image);
        margin-top: 0;
      }
      .map_wrap_outer {
        width: 100%;
        &.shrink {
          margin-left: auto;
          margin-right: auto;
        }
        .map_wrap_inner {
          padding-top: calc(var(--aspect-ratio-map) * 100%);
          position: relative;
          display: block;
          width: 100%;
          @include mq(tbl) {
            width: 100%;
          }
          iframe {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  &.reverse {
    .inner {
      @include mq(tbl) {
        flex-direction: row-reverse;
      }
    }
  }
}
