@charset "UTF-8";

@import "mixins";

main:has(.navigation.breadcrumbs) + #storelist {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(80px);
  }
}

#storelist {
  margin-top: vw(100px);
  @include mq(tbl) {
    margin-top: pc(170px);
  }
  .inner {
    @include container;
    .title {
      h2 {
        @include heading-small;
      }
      p {
        margin-top: vw(1px);
        margin-left: vw(2px);
        font-size: vw(10px);
        line-height: var(--line-height-xsmall);
        font-family: var(--font-family-en);
        text-transform: uppercase;
        @include mq(tbl) {
          font-size: pc(12px);
          margin-top: pc(5px);
          margin-left: pc(2px);
        }
      }
    }
    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: vw(10px);
      column-gap: vw(10px);
      margin-top: vw(30px);
      @include mq(tbl) {
        margin-top: pc(90px);
      }
      @include mq(tbl) {
        grid-template-columns: repeat(7, 1fr);
        row-gap: pc(30px);
        column-gap: pc(30px);
      }
      a {
        aspect-ratio: 104 / 146;
        display: block;
        background-color: var(--color-surface);
        border-radius: vw(7px);
        @include hoverShadow;
        @include mq(tbl) {
          border-radius: pc(7px);
        }
        .logo {
          text-align: center;
          aspect-ratio: 20 / 12;
          padding: vw(16px) vw(6px) 0;
          @include mq(tbl) {
            padding: pc(20px) pc(10px) 0;
          }
          img {
            max-width: 100%;
            max-height: 100%;
            aspect-ratio: 20 / 12;
            object-fit: contain;
          }
        }
        .fullahead {
          margin-top: vw(8px);
          padding-inline: vw(3px);
          text-align: center;
          @include mq(tbl) {
            margin-top: pc(18px);
            padding-inline: pc(5px);
          }
          img {
            width: 70%;
            margin-bottom: vw(2px);
            @include mq(tbl) {
              width: pc(50px);
              margin-bottom: pc(2px);
            }
          }
        }
        h3 {
          margin-top: vw(2px);
          font-size: vw(11px);
          padding: 0 vw(5px) vw(10px);
          text-align: center;
          @include heading;
          color: var(--color-text);
          line-height: var(--line-height-xsmall);
          @include mq(tbl) {
            margin-top: pc(2px);
            font-size: pc(15px);
            padding: 0 pc(5px) pc(10px);
          }
          span {
            letter-spacing: -.1em;
          }
          i {
            display: block;
          }
        }
      }
    }
  }
}