@charset "UTF-8";

@import "mixins";

.component.type_catchcopy_image_bg {
  margin-top: vw(80px);
  @include mq() {
    @include container;
    margin-top: pc(120px);
    padding-bottom: pc(70px);
  }
  .inner {
    @include mq() {
      position: relative;
    }
    .image {
      width: vw(300px);
      @include mq() {
        width: pc(700px);
      }
      img {
        object-fit: cover;
        border-radius: 0 vw(7px) vw(7px) 0;
        @include mq() {
          border-radius: pc(7px);
        }
      }
    }
    .text {
      background-color: var(--color-accent);
      border-radius: vw(7px) 0 0 vw(7px);
      padding: vw(30px);
      color: var(--color-text-on-accent);
      width: vw(280px);
      margin-top: vw(-35px);
      position: relative;
      margin-left: auto;
      @include gsap01;
      @include mq() {
        position: absolute;
        margin-top: unset;
        right: 0;
        bottom: 0;
        width: pc(380px);
        padding: pc(45px) pc(40px) pc(40px);
        bottom: pc(-70px);
        border-radius: pc(18px);
      }
      h3 {
        @include heading-xsmall;
        margin-bottom: vw(4px);
        @include mq() {
          margin-bottom: pc(4px);
        }
      }
      .copy {
        h4 {
          @include heading-small;
          color: var(--color-brand);
        }
      }
      .desc {
        margin-top: vw(12px);
        @include mq(tbl) {
          margin-top: pc(16px);
        }
        p {
          font-size: vw(12px);
          line-height: var(--line-height-large);
          @include mq(tbl) {
            font-size: pc(16px);
            line-height: var(--line-height-xlarge);
          }

        }
      }
      .button {
        margin-top: vw(24px);
        @include mq(tbl) {
          margin-top: pc(32px);
        }
      }
    }
  }
  &.reverse {
    .inner {
      .image {
        margin-left: auto;
        img {
          border-radius: vw(7px) 0 0 vw(7px);
          @include mq {
            border-radius: pc(7px);
          }
        }
      }
      .text {
        margin-left: 0;
        margin-right: auto;
        border-radius: 0 vw(7px) vw(7px) 0;
        @include mq() {
          right: auto;
          left: 0;
          border-radius: pc(18px);
        }
      }
    }
  }
  &.is-active {
    .inner {
      .text {
        @include gsap01-active;
      }
    }
  }
}
