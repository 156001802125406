@charset "UTF-8";

@import "mixins";

.component.type_catalog {
  margin-top: vw(50px);
  @include mq(tbl) {
    margin-top: pc(70px);
  }
  .inner {
    @include container;
    .list {
      margin-top: vw(30px);
      @include grid(1,vw(30px),0);
      @include mq(tb) {
        margin-top: pc(30px);
        @include grid(3,0,pc(70px));
        padding-bottom: pc(32px);
        border-bottom: pc(.5px) solid var(--color-border);
      }
      .unit {
        position: relative;
        @include mq(tb) {
          display: grid;
          grid-template-rows: subgrid;
          grid-row: span 2;
          gap: 0;
        }
        &:before {
          @include pd();
          @include mq(tb) {
            width: pc(.5px);
            height: 100%;
            right: calc(pc(70px) / 2 * -1);
            background-color: var(--color-border);
          }
        }
        &:nth-child(3n){
          &:before {
            content: none;
          }
        }
        .main {
          display: flex;
          justify-content: space-between;
          @include mq(tb) {
            display: block;
          }
          .image {
            width: var(--col-width-catalog-image);
            @include mq(tb) {
              width: 100%;
            }
            a {
              display: block;
              @include mq(tb) {
                aspect-ratio: var(--aspect-ratio-catalog-pc);
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            img {
              border-radius: 0;
              @include hoverShadow;
              @include mq(tb) {
                max-height: 100%;
              }
            }
          }
          .desc {
            width: var(--col-width-catalog-text);
            @include mq(tb) {
              width: auto;
            }
            .title {
              padding-bottom: 0;
              font-size: vw(16px);
              line-height: var(--line-height-small);
              @include heading;
              @include mq(tb) {
                margin-top: pc(24px);
                font-size: pc(20px);
              }
              @include mq(tbl) {
                @include hoverOp;
              }
              a {
                color: var(--color-text);
                position: relative;
                text-decoration: none;
                word-break: break-all;
              }
            }
            .subtitle {
              color: var(--color-text-weaker);
              font-size: vw(12px);
              margin-top: vw(4px);
              line-height: var(--line-height-medium);
              @include mq(tbl) {
                font-size: pc(14px);
                margin-top: pc(4px);
              }
            }
            .format {
              font-size: vw(10px);
              margin-top: vw(4px);
              @include mq(tbl) {
                font-size: pc(12px);
                margin-top: pc(12px);
              }
              img {
                width:  vw(18px);
                height: vw(20px);
                object-fit: contain;
                margin-right: vw(8px);
                vertical-align: middle;
                @include mq(tbl) {
                  width: pc(18px);
                  height: pc(20px);
                  margin-right: pc(8px);
                }
              }
              span {
                text-transform: uppercase;
                letter-spacing: 0;
              }
              a {
                color: var(--color-text-weaker);
                text-decoration: none;
                @include hoverOp;
              }
            }
          }
        }
        .summary {
          margin-top: vw(12px);
          font-size: vw(12px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            font-size: pc(14px);
            margin-top: pc(12px);
          }
        }
      }
    }
  }
}
