@charset "UTF-8";

@import "mixins";

.navigation.articlelist {
  margin-top: vw(60px);
  @include mq(tbl) {
    margin-top: pc(80px);
  }
  .inner {
    @include container;
    .list {
      @include mq(tbl) {
        @include grid(2,pc(60px),pc(80px));
      }
      .unit {
        display: flex;
        position: relative;
        margin-top: vw(32px);
        gap: vw(16px);
        @include mq(tbl) {
          margin-top: unset;
          gap: pc(35px);
        }
        &:first-child {
          margin-top: unset;
        }
        .image {
          flex: 0 1 auto;
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: vw(100px);
            @include mq(tbl) {
              width: pc(160px);
            }
            img {
              // aspect-ratio: 120 / 63;
              object-fit: cover;
              border-radius: vw(7px);
              @include hoverShadow;
              @include mq(tbl) {
                border-radius: pc(7px);
              }
            }
          }
        }
        .desc {
          flex: 1;
          padding-top: vw(2px);
          @include mq(tbl) {
            padding-top: pc(2px);
          }
          .date {
            font-size: vw(10px);
            font-family: var(--font-family-en);
            @include mq(tbl) {
              font-size: pc(12px);
            }
          }
          .cat {
            margin-top: vw(8px);
            @include mq(tbl) {
              margin-top: pc(12px);
            }
            a {
              display: inline-block;
              margin: 0 vw(4px) vw(4px) 0;
              font-size: vw(10px);
              color: var(--color-text);
              @include heading;
              background-color: var(--color-surface);
              padding: vw(4px) vw(8px);
              text-align: center;
              text-decoration: none;
              @include hoverBGColor(var(--color-brand));
              @include mq(tbl) {
                font-size: pc(12px);
                margin: 0 pc(4px) pc(4px) 0;
                padding: pc(4px) pc(8px);
              }
            }
          }
          .title {
            margin-top: vw(6px);
            margin-bottom: vw(8px);
            position: relative;
            font-size: vw(18px);
            line-height: var(--line-height-xsmall);
            @include heading;
            translate: vw(-1px) 0;
            @include mq(tbl) {
              font-size: pc(24px);
              margin-top: pc(8px);
              margin-bottom: pc(14px);
              translate: pc(-1px) 0;
            }
            a {
              text-decoration: none;
              color: currentColor;
              @include hoverColor(var(--color-accent));
            }
          }
          .summary {
            font-size: vw(11px);
            line-height: var(--line-height-medium);
            @include mq(tbl) {
              font-size: pc(14px);
            }
          }
        }
      }
    }
    .more {
      margin-top: 40px;
      text-align: center;
    }
  }
}
