@charset "UTF-8";

@import "mixins";

.component.type_speaker {
  margin-top: vw(70px);
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  .inner {
    @include container;
    @include mq(tbl) {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: flex-start;
    }
    .speaker {
      background-color: var(--color-speaker-bg);
      padding: vw(30px) vw(20px);
      color: var(--color-speaker-text);
      border-radius: vw(20px);
      @include mq(tbl) {
        position: sticky;
        top: 0;
        width: var(--col-width-speaker-panel);
        margin-top: pc(10px);
        padding: pc(30px) pc(20px);
        border-radius: pc(24px);
        &.static {
          position: static;
        }
      }
      .heading {
        font-size: vw(12px);
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-medium);
        color: var(--color-third);
        @include mq(tbl) {
          font-size: pc(14px);
        }
      }
      .unit {
        margin-top: vw(16px);
        @include mq(tbl) {
          margin-top: pc(24px);
        }
        + .unit {
          margin-top: vw(32px);
          @include mq(tbl) {
            margin-top: pc(48px);
          }
        }
        .photo {
          img {
            border-radius: vw(7px);
            @include mq(tbl) {
              border-radius: pc(7px);
            }
          }
        }
        .name {
          margin-top: vw(16px);
          @include mq(tbl) {
            margin-top: pc(16px);
          }
          dt {
            @include heading-small;
            &:last-child {
              margin-bottom: unset;
            }
          }
          dd {
            font-family: var(--font-family-en);
            font-size: vw(9px);
            margin-top: vw(3px);
            text-transform: uppercase;
            @include mq(tbl) {
              font-size: pc(12px);
              margin-top: pc(5px);
            }
          }
        }
        .profile {
          margin-top: vw(16px);
          font-size: vw(12px);
          line-height: var(--line-height-medium);
          @include mq(tbl) {
            margin-top: pc(24px);
            font-size: pc(14px);
          }
          &.en {
            margin-top: vw(8px);
            font-family: var(--font-family-en);
            @include mq(tbl) {
              margin-top: pc(12px);
            }
          
          }
        }
      }
    }
    .text {
      margin-top: vw(24px);
      @include mq(tbl) {
        width: var(--col-width-speaker-content);
        margin-top: 0;
      }
      .float_image {
        @include clearfix;
        padding-bottom: vw(32px);
        figure {
          float: right;
          width: var(--col-width-float-image);
          padding-left: var(--gap-float-image);
          padding-top: vw(8px);
          padding-bottom: vw(8px);
          figcaption {
            @include caption;
          }
        }
      }
      .component {
        margin-top: var(--space-medium);
        padding-bottom: var(--space-large);
        @include mq(tbl) {
          margin-top: var(--space-large);
          padding-bottom: var(--space-xxlarge);
        }
        .container {
          padding: 0;
          display: block;
          .inner {
            .text {
              margin-top: 0;
              width: 100%;
            }
          }
        }
      }
      .type_table {
        padding-left: 0;
        padding-right: 0;
        .inner {
          padding: 0;
          display: block;
        }
      }
    }
  }
}
