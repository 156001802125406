@charset "UTF-8";

@import "mixins";

.component.type_speaker_heading {
  position: relative;
  margin-top: vw(70px);
  padding-inline: vw(16px);
  @include mq(tbl) {
    margin-top: pc(70px);
    @include container;
  }
  @include mq(pc) {
    padding-left: calc(50% - (1100px / 2));
    padding-right: calc(50% - (1100px / 2));
  }
  &.reverse {
    .inner {
      .card {

      }
    }
  }
  .inner {
    position: relative;
    @include mq(tbl) {
      padding: pc(55px) pc(80px);
      display: grid;
      align-items: center;
      grid-template:
        "card image" auto
        / 60% 40%;
    }
    @include mq(pc) {
      padding-inline: pc(135px);
    }
    &::before, &::after {
      content: '';
      position: absolute;
      inset: 0;
      background-color: var(--color-surface);
      border-radius: vw(40px);
      z-index: -1;
      mix-blend-mode: soft-light;
      @include mq(tbl) {
        border-radius: pc(80px);
      }
    }
    &::before {
      z-index: -2;
      mix-blend-mode: unset;
    }
    .image {
      position: relative;
      z-index: 2;
      padding-inline: vw(60px);
      translate: 0 vw(-20px);
      @include mq(tbl) {
        padding-inline: 0;
        grid-area: image;
        translate: unset;
      }
      &::after {
        @include pd;
        background-image: url(/images/card.png);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        aspect-ratio: 302 / 423;
        z-index: 2;
        left: 0;
        top: 0;
        bottom: 0;
        margin-inline: vw(60px);
        transform-origin: center bottom;
        opacity: 0;
        translate: 0 vw(20px);
        transition:
          filter var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          opacity var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          translate var(--bezier) var(--transition-speed-slow) var(--transition-speed-slow),
          scale var(--bezier) .7s var(--transition-speed-slow),
          rotate var(--bezier) .7s var(--transition-speed-slow);
        @include mq(tbl) {
          margin-inline: unset;
          translate: 0 pc(20px);
        }
      }
      img {
        width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 3;
        opacity: 0;
        translate: 0 vw(20px);
        @include gsap01;
        @include mq(tbl) {
          height: auto;
          max-width: 100%;
          translate: 0 pc(20px);
        }
      }
    }
    .card {
      padding: vw(30px) vw(30px) vw(40px);
      @include mq(tbl) {
        grid-area: card;
        padding: unset;
        padding-right: pc(40px);
      }
      .tag {
        background-color: var(--color-third);
        display: inline-block;
        font-size: vw(11px);
        font-weight: 600;
        color: var(--color-text-on-accent);
        padding: vw(4px) vw(6px);
        border-radius: vw(3px);
        margin-bottom: vw(20px);
        @include mq(tbl) {
          font-size: pc(16px);
          padding: pc(8px) pc(10px);
          border-radius: pc(5px);
          margin-bottom: pc(25px);
        }
      }
      h2 {
        @include heading-medium;
        translate: vw(-2px) 0 ;
        @include mq(tbl) {
          translate: pc(-2px) 0 ;
        }
      }
      .position {
        margin-top: vw(20px);
        margin-bottom: vw(10px);
        font-size: vw(11px);
        font-weight: 600;
        @include mq(tbl) {
          font-size: pc(16px);
          margin-top: pc(30px);
          margin-bottom: pc(15px);
        }
      }
      .name {
        @include heading-small;
        translate: vw(-2px) 0 ;
        @include mq(tbl) {
          translate: pc(-2px) 0 ;
        }
      }
      .en {
        font-family: var(--font-family-en);
        font-size: vw(9px);
        margin-top: vw(3px);
        text-transform: uppercase;
        @include mq(tbl) {
          font-size: pc(12px);
          margin-top: pc(5px);
        }

      }
    }
  }
  &.is-active {
    .inner {
      .image {
        &::after {
          @include gsap01-active;
          rotate: z 14deg;
          scale: .95;
        }
        img {
          @include gsap01-active;
        }
      }
    }
  }
}
