@charset "UTF-8";

@import "mixins";

.component.type_faq {
  margin-top: vw(70px);
  @include mq(tbl) {
    margin-top: pc(100px);
  }
  .inner {
    @include container;
    .unit {
      background-color: var(--color-surface);
      border-radius: vw(17px);
      padding-inline-start: vw(10px);
      margin-top: vw(16px);
      @include mq(tbl) {
        border-radius: pc(17px);
        margin-top: pc(22px);
      }
      &:first-child {
        margin-top: 0;
      }
      .q {
        padding: vw(24px) vw(36px);
        font-size: vw(16px);
        line-height: var(--line-height-medium);
        position: relative;
        @include heading;
        @include mq(tbl) {
          padding: pc(32px) pc(80px) pc(32px) pc(64px);
          font-size: pc(20px);
          cursor: pointer;
          @include hoverColor(var(--color-accent));
        }
        &.active {
          &:after {
            transform: rotate(180deg);
          }
        }
        &:after {
          @include pd;
          width: vw(13px);
          height: vw(15px);
          background: center / contain no-repeat var(--arrow-faq);
          right: vw(15px);
          top: 50%;
          margin-top: vw(-7px);
          transition: transform var(--transition-speed-fast) var(--bezier);
          @include mq(tbl) {
            width: pc(20px);
            height: pc(18px);
            margin-top: pc(-9px);
            right: pc(30px);
          }
        }
        &:before {
          @include pd;
          content: 'Q';
          top: vw(12px);
          left: vw(6px);
          font-size: vw(28px);
          line-height: var(--line-height-medium);
          height: 1hl;
          font-family: var(--font-family-en);
          font-weight: var(--font-weight-bold);
          @include mq(tbl) {
            font-size: pc(36px);
            left: pc(20px);
            top: pc(16px);
          }
        }
      }
      .a {
        padding: 0 vw(36px) vw(24px);
        font-size: vw(13px);
        line-height: var(--line-height-large);
        position: relative;
        display: none;
        @include mq(tbl) {
          padding: 0 pc(80px) pc(40px) pc(64px);
          font-size: pc(16px);
        }
        &:before {
          @include pd;
          content: 'A';
          top: vw(-4px);
          left: vw(6px);
          font-size: vw(28px);
          line-height: 1;
          color: var(--color-third);
          font-family: var(--font-family-en);
          font-weight: var(--font-weight-bold);
          @include mq(tbl) {
            font-size: pc(36px);
            left: pc(20px);
            top: pc(-6px);
          }
        }
        &:after {
          content: '';
          display: block;
          clear: both;
        }
        img {
          display: block;
          margin: 0 auto vw(12px) !important;
          &.mt-image-right {
            float: none !important;
            @include mq(tbl) {
              float: right !important;
              margin: 0 0 pc(24px) pc(24px) !important;
            }
          }
          &.mt-image-left {
            float: none !important;
            @include mq(tbl) {
              float: left !important;
              margin: 0 pc(24px) pc(24px) 0 !important;
            }
          }
        }
        p {
          padding-bottom: vw(24px);
          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
